import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core/api.service';


@Injectable()
export class CopyExistingService {

  constructor(private apiService: ApiService) {
  }

  getExistingPlans(params): Observable<any> {
    return this.apiService.getLearningTemplates(params).pipe(
      map(response => response.templatesLibrary));
  }
}


