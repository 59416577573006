<div class="learning-item-form plan" [ngClass]="{'edit-mode': editMode, 'conflict': currentStep === CreateNewItemWizard.ItemDetailsUrlExists, 'selectable-items': selectableItems}">

  <div class="library-item-form-header">
    <div>
      <button class="btn btn-small tertiary btn-icon back-button" (click)="backToEdit()" *ngIf="editMode && (currentStep === CreateNewItemWizard.ItemDetailsUrlExists || currentStep === CreateNewItemWizard.WebsiteLink)">
        <img lib-amdocs-image-version class="icon" src="/assets/icons/black-prev.svg"/>
      </button>

      <span class="label-h4" *ngIf="!editMode">{{ 'createNewLearningItemPopup.title' | amdocsTranslate }}</span>
      <span class="label-h4" *ngIf="editMode && currentStep === CreateNewItemWizard.ItemDetailsSkip">{{ 'createNewLearningItemPopup.editItemDetails' | amdocsTranslate }}</span>
      <span class="label-h4" *ngIf="editMode && currentStep !== CreateNewItemWizard.ItemDetailsSkip">{{ 'createNewLearningItemPopup.editItemLink' | amdocsTranslate }}</span>
    </div>

    <div class="close-popup-button" role="button" (click)="close()" >
      <img src="/assets/icons/popup-close.svg" />
    </div>
  </div>


  <div class="create-learning-item-content">
    <lib-amdocs-app-loader *ngIf="showLoader"></lib-amdocs-app-loader>
    <div class="website-link-wrapper" *ngIf="currentStep === CreateNewItemWizard.WebsiteLink">
      <lib-amdocs-input-field
        class="full-width"
        [elementId]="'websiteLink-' + item?.code"
        [placeHolder]="'createNewLearningItemPopup.websiteLinkPlaceholder' | amdocsTranslate"
        [control]="urlControl"
        [hideLabel]="true"
        [errorsMessages]="urlErrors"
        (keyUpCallback)="utils.trimSpacesInControl($event, urlControl)"
      ></lib-amdocs-input-field>

      <div class="buttons-wrapper">
        <button class="btn dark btn-small icon-button" (click)="next()" [disabled]="!urlControl.value || showLoader">
          <div class="text">
            <span class="btn-text-next">{{'createNewLearningItemPopup.next' | amdocsTranslate}}</span>
            <img lib-amdocs-image-version class="button-icon icon-after" src="/assets/icons/next-arrow.svg"/>
          </div>
        </button>
        <div class="btn-separator"></div>
        <button class="btn secondary btn-small icon-button" (click)="skip()" [disabled]="showLoader">
          <div class="text">
            <span>{{'createNewLearningItemPopup.itemWithoutLink' | amdocsTranslate}}</span>
            <img lib-amdocs-image-version class="button-icon icon-after" src="/assets/icons/next-arrow-dark-16.svg"/>
          </div>
        </button>
      </div>
    </div>

    <div class="similar-exists-wrapper" *ngIf="!showLoader && currentStep === CreateNewItemWizard.ItemDetailsUrlExists">
      <div class="url-wrapper">
        <div class="url">
          <lib-amdocs-input-field
            [elementId]="'websiteLink-' + item?.code"
            [placeHolder]="'createNewLearningItemPopup.websiteLinkPlaceholder' | amdocsTranslate"
            [hideLabel]="true"
            [control]="urlControl"
            [errorsMessages]="urlErrors"
            (keyUpCallback)="utils.trimSpacesInControl($event, urlControl)"
          ></lib-amdocs-input-field>
        </div>
        <button class="add-url" (click)="editExistingUrl()">{{'createNewLearningItemPopup.form.editLink' | amdocsTranslate}}</button>
        <div class="btn-separator" *ngIf="canCreate"></div>
        <button class="btn dark btn-small icon-button" *ngIf="canCreate" (click)="skipForWhitelist()" [disabled]="showLoader">
          <div class="text">
            <span>{{'createNewLearningItemPopup.createNewItem' | amdocsTranslate}}</span>
            <img lib-amdocs-image-version class="button-icon icon-after" src="/assets/icons/next-arrow.svg"/>
          </div>
        </button>
      </div>
      <div
        *ngIf="existingItems.length === 1 && !canCreate"
        class="create-learning-item-content-title"
      >
        {{ 'createNewLearningItemPopup.similarExists' | amdocsTranslate }}
      </div>
      <div
        *ngIf="existingItems.length > 1 && !canCreate"
        class="create-learning-item-content-title"
      >
        {{ 'createNewLearningItemPopup.similarExistsPlural' | amdocsTranslate }}
      </div>
      <div
        *ngIf="existingItems.length === 1 && canCreate"
        class="create-learning-item-content-title whitelist-title"
      >
        {{ 'createNewLearningItemPopup.similarExistsGo' | amdocsTranslate }}
      </div>
      <div
        *ngIf="existingItems.length > 1 && canCreate"
        class="create-learning-item-content-title whitelist-title"
      >
        {{ 'createNewLearningItemPopup.similarExistsPluralGo' | amdocsTranslate }}
      </div>
      <div class="existing-learning-plan-item">
        <ng-container *ngFor="let eItem of paginatedExistingItems(existingItems)">
          <app-learning-plan-item [item]="eItem"
                                  [selectable]="selectableItems"
                                  (checkCallback)="checkItem($event)"
                                  [isChecked]="isItemChecked(eItem.code)"
                                  [learningPlanType]="editMode ? LearningPlanType.EditLearningItem : LearningPlanType.CreateLearningItem"></app-learning-plan-item>
        </ng-container>

        <app-paginator
          *ngIf="existingItems.length > existingItemsPageNumber"
          [pageSize]="existingItemsPageSize"
          [pageNumber]="existingItemsPageNumber"
          [totalRows]="existingItems.length"
          (onPageChange)="existingItemsOnPageChange($event)"
        >
        </app-paginator>
      </div>
    </div>
    <div class="item-details-wrapper" *ngIf="!showLoader && currentStep === CreateNewItemWizard.ItemDetailsSkip">
      <div class="url-wrapper">
        <div class="url">
          <lib-amdocs-input-field
            [elementId]="'websiteLink-' + item?.code"
            [placeHolder]="'createNewLearningItemPopup.websiteLinkPlaceholderBlank' | amdocsTranslate"
            [label]="'createNewLearningItemPopup.form.url' | amdocsTranslate"
            [control]="urlControl"
            [errorsMessages]="urlErrors"
            (keyUpCallback)="utils.trimSpacesInControl($event, urlControl)"
          ></lib-amdocs-input-field>
        </div>
        <button class="add-url" (click)="addUrl()" *ngIf="urlControl.value">{{'createNewLearningItemPopup.form.editLink' | amdocsTranslate}}</button>
        <button class="add-url" (click)="addUrl()" *ngIf="!urlControl.value">{{'createNewLearningItemPopup.form.addLink' | amdocsTranslate}}</button>
      </div>
      <div class="form-row-wrapper">
        <div class="full-width">
          <lib-amdocs-input-field
            [elementId]="'title-' + item?.code"
            [control]="titleControl"
            [errorsMessages]="{required: 'errorsMessages.required' | amdocsTranslate}"
            [label]="'createNewLearningItemPopup.form.title' | amdocsTranslate"
            [placeHolder]="'createNewLearningItemPopup.form.titlePlaceholder' | amdocsTranslate"
            [inputMaxLength]="200"
            [isRequired]="true"
          ></lib-amdocs-input-field>
        </div>
        <div class="full-width">
          <lib-amdocs-autocomplete [label]="'createNewLearningItemPopup.form.learningType' | amdocsTranslate"
                                   [placeholder]="'createNewLearningItemPopup.form.learningTypeP'| amdocsTranslate"
                                   [isMultiple]="false"
                                   [customClass]="'new-design'"
                                   [control]="learningTypeControl"
                                   [errorsMessage]="learningTypeError"
                                   (itemChangedCallback)="onChangeDropDown($event, learningTypeControl)"
                                   [selectedItemCode]="learningTypeControl.value"
                                   [isRequired]="true"
                                   [items]="learningTypes">
          </lib-amdocs-autocomplete>
        </div>
        <div class="duration-wrapper">
          <div class="duration-input">
            <lib-amdocs-input-field
              [elementId]="'duration-' + item?.code"
              [control]="durationControl"
              [label]="'createNewLearningItemPopup.form.duration' | amdocsTranslate"
              [inputMode]="'numeric'"
              [errorsMessages]="{
                required: 'errorsMessages.required' | amdocsTranslate,
                greaterThanZero: 'errorsMessages.invalidNumber' | amdocsTranslate
              }"
              (keyDownCallback)="utils.onlyNumbers($event)"
              [isRequired]="true"
            ></lib-amdocs-input-field>
          </div>
          <div class="duration-type">
            <lib-amdocs-dropdown [elementId]="'duration-type-' + item?.code"
                                 [items]="durationTypes"
                                 [newDesign]="true"
                                 [placeHolder]="''"
                                 [control]="durationTypeControl"
                                 [label]="''"></lib-amdocs-dropdown>
          </div>
        </div>
      </div>
      <div class="description-wrapper">
        <lib-amdocs-large-text-input [elementId]="'description-' + item?.code"
                                     [control]="descriptionControl"
                                     [inputMaxLength]="9999"
                                     [errorsMessages]="{required: 'errorsMessages.required' | amdocsTranslate}"
                                     [placeHolder]="'createNewLearningItemPopup.form.descriptionP' | amdocsTranslate"
                                     [label]="'createNewLearningItemPopup.form.description' | amdocsTranslate"
                                     [isRequired]="true"
        ></lib-amdocs-large-text-input>
      </div>

      <div class="form-row-wrapper">

        <div class="full-width">
          <lib-amdocs-autocomplete [label]="'createNewLearningItemPopup.form.domain' | amdocsTranslate"
                                   [placeholder]="'createNewLearningItemPopup.form.domainP'| amdocsTranslate"
                                   [isMultiple]="false"
                                   [elementId]="'learningType-' + item?.code"
                                   (itemChangedCallback)="onChangeDropDown($event, domainControl)"
                                   [customClass]="'new-design'"
                                   [control]="domainControl"
                                   [errorsMessage]="domainError"
                                   [selectedItemCode]="domainControl.value"
                                   [isRequired]="true"
                                   [items]="domains">
          </lib-amdocs-autocomplete>
        </div>
        <div class="full-width">
          <lib-amdocs-input-field
            [elementId]="'technology-' + item?.code"
            [control]="technologyControl"
            [label]="'createNewLearningItemPopup.form.technology' | amdocsTranslate"
            [placeHolder]="'createNewLearningItemPopup.form.technologyP' | amdocsTranslate"
          ></lib-amdocs-input-field>
        </div>
        <!-- <div class="field-width">
          <lib-amdocs-autocomplete [label]="'createNewLearningItemPopup.form.role' | amdocsTranslate"
                                   [isMultiple]="true"
                                   [customClass]="'new-design'"
                                   [elementId]="'role'"
                                   [control]="roleControl"
                                   (itemChangedCallback)="onChangeDropDownMultiple($event, roleControl)"
                                   [items]="roles">
          </lib-amdocs-autocomplete>
        </div> -->
        <div class="full-width">
          <lib-amdocs-autocomplete [label]="'createNewLearningItemPopup.form.account' | amdocsTranslate"
                                   [isMultiple]="false"
                                   [elementId]="'accounts-' + item?.code"
                                   [customClass]="'new-design'"
                                   [control]="accountControl"
                                   [placeholder]="'createNewLearningItemPopup.form.accountP' | amdocsTranslate"
                                   (itemChangedCallback)="onChangeDropDown($event, accountControl)"
                                   [selectedItemCode]="accountControl.value"
                                   [items]="accounts">
          </lib-amdocs-autocomplete>
        </div>

      </div>
      <div class="form-row-wrapper">
        <div class="full-width">
          <lib-amdocs-autocomplete [label]="'createNewLearningItemPopup.form.product' | amdocsTranslate"
                                   [isMultiple]="true"
                                   [customClass]="'new-design'"
                                   [elementId]="'product-' + item?.code"
                                   [control]="productControl"
                                   [placeholder]="'createNewLearningItemPopup.form.productP' | amdocsTranslate"
                                   (itemChangedCallback)="onChangeDropDownMultiple($event, productControl)"
                                   [selectedItemCode]="productControl.value"
                                   [items]="products"
                                   [noDropdown]="true"
                                   (selectOpen)="openModalProductPractice()"
          ></lib-amdocs-autocomplete>
        </div>
        <!-- <div class="field-width">
          <lib-amdocs-autocomplete [label]="'createNewLearningItemPopup.form.location' | amdocsTranslate"
                                   [isMultiple]="false"
                                   [elementId]="'location'"
                                   [customClass]="'new-design'"
                                   (itemChangedCallback)="onChangeDropDown($event, locationControl)"
                                   [control]="locationControl"
                                   [items]="locations">
          </lib-amdocs-autocomplete>
        </div> -->
        <button *ngIf="!editMode" class="btn primary btn-small submit-item" (click)="createItem()">{{'createNewLearningItemPopup.form.createItem' | amdocsTranslate}}</button>
        <button *ngIf="editMode" class="btn primary btn-small submit-item" (click)="updateItem()">{{'createNewLearningItemPopup.form.saveChanges' | amdocsTranslate}}</button>
      </div>
    </div>

  </div>

</div>
