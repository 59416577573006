import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../core/api.service';
import { OrgUnit } from '../../models/learningPlan';

@Injectable({
  providedIn: 'root'
})
export class CreateLearningPlanService {

  constructor(private apiService: ApiService) { }


  public initDropDowns(): Observable<any> {
    const calls = [
      this.getOrg2Units(),
      this.getRoles(),
      this.getSeniorityLevels(),
      this.getProducts(),
      this.getAccounts(),
      this.getLocations(),
      this.getLearningDomains(),
      this.getLearningTypes()
    ];

    return forkJoin(calls).pipe(
      mergeMap((response: any[]) => {
        const data = {
          org2Units: response[0],
          roles: response[1].roles,
          seniorityLevels: response[2].seniorityLevels,
          managedProducts: response[3].products.managed,
          customProducts: response[3].products.custom,
          accounts: response[4].accounts,
          locations: response[5].locations,
          learningDomains: response[6].learningDomains,
          learningTypes: response[7].learningTypes
        };

        return of(data);
      })
    );
  }

  getSeniorityLevels(): Observable<any> {
    return this.apiService.getSeniorityLevels();
  }

  getProducts(): Observable<any> {
    return this.apiService.getProducts();
  }

  addCustomProduct(params): Observable<any> {
    return this.apiService.addCustomProduct(params);
  }

  getAccounts(): Observable<any> {
    return this.apiService.getAccounts();
  }

  getLocations(): Observable<any> {
    return this.apiService.getLocations();
  }

  getLearningDomains(): Observable<any> {
    return this.apiService.getLearningDomains();
  }

  getRoles(): Observable<any> {
    return this.apiService.getRoles();
  }

  getOrg2Units(): Observable<OrgUnit[]> {
    return this.apiService.getOrg2Units();
  }

  getOrg3Units(unitCode = ''): Observable<OrgUnit[]> {
    return this.apiService.getOrg3Units(unitCode);
  }

  getOrg4Units(unitCode = ''): Observable<OrgUnit[]> {
    return this.apiService.getOrg4Units(unitCode);
  }

  createLearningTemplate(params): Observable<any> {
    return this.apiService.createLearningTemplate(params);
  }

  updateLearningTemplate(params): Observable<any> {
    return this.apiService.updateLearningTemplate(params);
  }

  createLearningItem(params): Observable<any> {
    return this.apiService.createLearningItem(params);
  }

  upsertLearningItem(params): Observable<any> {
    return this.apiService.upsertLearningItem(params);
  }

  updateLearningItemActivation(params): Observable<any> {
    return this.apiService.updateLearningItemActivation(params);
  }

  getLearningTemplate(code: string, editView = false): Observable<any> {
    return this.apiService.getLearningTemplate(code, editView);
  }

  publishLearningTemplate(code: string): Observable<any> {
    return this.apiService.publishLearningTemplate(code);
  }

  deleteLearningTemplate(code: string): Observable<any> {
    return this.apiService.deleteLearningTemplate(code);
  }

  moveTemplateToDraft(code: string): Observable<any> {
    return this.apiService.moveTemplateToDraft(code);
  }

  getUserLearningTemplates(params): Observable<any> {
    return this.apiService.getUserLearningTemplates(params).pipe(
      map(response =>  response.userTemplates));
  }

  getLearningTypes(): Observable<any> {
    return this.apiService.getLearningTypes();
  }
}
