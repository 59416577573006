<div class="mh-popup-header">
  <span class="label-h1">{{ "homePage.copyPlan" | amdocsTranslate }}</span>
  <div class="close-popup-button" role="button" (click)="bsModalRef.hide()">
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>

<div class="modal-body d-flex flex-column justify-content-center mb-5">
  <div class="search-input-wrapper copy offset-7 col-5 text-right mt-4">
    <lib-amdocs-input-field [elementId]="'searchPlan'" [hideLabel]="true"
      [placeHolder]="'learningPlan.searchPlaceholder' | amdocsTranslate" [control]="searchFC">
    </lib-amdocs-input-field>
    <img class="find-an-item-icon" lib-amdocs-image-version src="/assets/icons/find-an-item.svg" />
  </div>
  <div class="list" *ngIf="existingPlans && existingPlans.length">
    <div class="card mt-2 mb-3" *ngFor="let template of existingPlans">
      <app-learning-template class="copy-existing-templates" [template]="template" [showAdditionalBlock]="false"
        [isCopy]="true" (refreshTemplates)="isRefreshTemplates($event)"
        (getCopyCode)="makeCopy($event)"></app-learning-template>
    </div>
  </div>
  <div class="alternative-actions-wrapper" *ngIf="existingPlans && !existingPlans.length && searchFC.value?.length">
    <div class="empty-search-wrapper">
      <img lib-amdocs-image-version class="pointer" src="/assets/icons/items-search/no_results.svg" />
      <div class="empty-search">{{'library.emptyResults' | amdocsTranslate}}</div>
    </div>
  </div>
  <app-paginator
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    [totalRows]="totalRows"
    (onPageChange)="onPageChange($event)">
  </app-paginator>
</div>