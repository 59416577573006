import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {

  @Input() title: string;
  @Input() text: string;
  @Input() img: string;
  @Input() isSmallTextWidth = true;
  @Input() userName: string;
  @Input() topSmallText: boolean;
  @Input() customClass: string;

  constructor() { }

  ngOnInit(): void {
  }

}
