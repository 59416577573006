export const misnpBaseURL = 'https://misnp.amdocs.com';
export const misBaseURL = 'https://mis.amdocs.com';

export const baseEnvironment = {
  loginGWBaseURL: '',
  graphQlBaseURL: '/graphql',
  imgUrl: '/sas/profilePicture',
  cnsBaseURL: '',
  avatarsVersion: '1',
  appId: 'amdocsU',
  refreshTokenInterval: 2400000
};

