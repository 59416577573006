import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { LearningItem } from 'src/app/models/learningPlan'

@Injectable({
  providedIn: 'root',
})
export class LibraryItemsSelectionsService {

  constructor(
  ) {
  }

  private checkedItems: Map<LearningItem['code'], LearningItem> = new Map()
   
  private itemsSelectionsSubject: BehaviorSubject<Array<LearningItem>> = new BehaviorSubject([])

  private getSeelectedItems(): Array<LearningItem> {
    return Array.from(this.checkedItems.values())
  }

  public selectedLibraryItemsSelector = this.itemsSelectionsSubject.asObservable()

  public checkSelection(code: LearningItem['code']): boolean {
    return this.checkedItems.has(code)
  }

  public clearSelections(): void {
    this.checkedItems.clear()
    this.itemsSelectionsSubject.next(this.getSeelectedItems())
  }

  public addSelection(learningItem): void {
    this.checkedItems.set(learningItem.code, learningItem)
    this.itemsSelectionsSubject.next(this.getSeelectedItems())
  }

  public removeSelection(code: LearningItem['code']): void {
    this.checkedItems.delete(code)
    this.itemsSelectionsSubject.next(this.getSeelectedItems())
  }
}
