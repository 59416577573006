<div class="learning-plan-wrapper">
  <div class="header-wrapper">
    <div class="label-h1"> {{ 'createPlan.learningPlan.title' | amdocsTranslate}}</div>
    <div class="total-part-duration" *ngIf="!getPlantTotalDuration()"><span>Total 0 hr</span></div>
    <div class="total-part-duration" *ngIf="getPlantTotalDuration()"><span>Total {{getPlantTotalDuration() | amdocsMinutesToHours:true}}</span></div>
  </div>

  <div class="learning-plan-content" [sortablejs]="parts" [sortablejsOptions]="sortablejsOptions">
    <div *ngFor="let part of parts; index as i; trackBy:partTracking" >
      <app-learning-plan-part
        [learningTypesList]="learningTypesList"
        [part]="part"
        [isPreviewMode]="isPreviewMode"
        (openLibraryCallback)="openLibrary(part.order)"
        (removeItemCallback)="removeItem($event, part.order)"
        (removeCallback)="openModalRemovePart(i, part)"
        (renameCallback)="onPartRename()"
        (editItemCallback)="onEditItem()"
        (editInLibraryCallback)="editInLibrary($event)"
      ></app-learning-plan-part>
   </div>
  </div>

  <button class="add-part-button" *ngIf="!isPreviewMode" (click)="addPart()">
    <div class="text">+ {{'library.addAPart'| amdocsTranslate}}</div>
  </button>

  <app-library
    [showLibrary]="showLibrary"
    [partId]="partId"
    [parts]="parts"
    [selectedPlan]="selectedPlan"
    [isEditLibraryMode]="isEditLibraryMode"
    (closeCallback)="closeLibrary()"
    (addItemsToLearningPlanCallback)="addItemsToLearningPlan($event)"
    (itemEditedCallback)="itemEdited($event)"
    (itemActivationChangedCallback)="itemActivationChanged($event)"
  ></app-library>

</div>
