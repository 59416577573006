import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CreateNewItemWizard, LearningItem, LearningPlanType} from '../../../models/learningPlan';
import {UtilsService} from '../../../core/utils.service';

@Component({
  selector: 'app-open-plan-popup',
  templateUrl: './open-plan-popup.component.html',
  styleUrls: ['./open-plan-popup.component.scss']
})
export class OpenPlanPopupComponent implements OnInit {

  @Input() showOpenPlanPopup = false;
  @Output() closeAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  public searchControl: UntypedFormControl = new UntypedFormControl();

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    this.init();
  }

  get utils(): UtilsService {
    return this.utilsService;
  }

  get items(): any {
    const items = [
      {
        planName: 'Plan Name',
        hours: 45,
        items: 18,
        expectedToFinish: '7 months',
        seniority: 'Junior',
        role: 'Example',
        product: 'Example',
        location: 'Example',
        account: 'Example',
        org2: 'Example',
        org3: 'Example',
        org4: 'Example',
        org5: 'Example'
      },
      {
        planName: 'Plan Name',
        hours: 45,
        items: 18,
        expectedToFinish: '7 months',
        seniority: 'Junior',
        role: 'Example',
        product: 'Example',
        location: 'Example',
        account: 'Example',
        org2: 'Example',
        org3: 'Example',
        org4: 'Example',
        org5: 'Example'
      },
      {
        planName: 'Plan Name',
        hours: 45,
        items: 18,
        expectedToFinish: '7 months',
        seniority: 'Junior',
        role: 'Example',
        product: 'Example',
        location: 'Example',
        account: 'Example',
        org2: 'Example',
        org3: 'Example',
        org4: 'Example',
        org5: 'Example'
      },
      {
        planName: 'Plan Name',
        hours: 45,
        items: 18,
        expectedToFinish: '7 months',
        seniority: 'Junior',
        role: 'Example',
        product: 'Example',
        location: 'Example',
        account: 'Example',
        org2: 'Example',
        org3: 'Example',
        org4: 'Example',
        org5: 'Example'
      },
      {
        planName: 'Plan Name',
        hours: 45,
        items: 18,
        expectedToFinish: '7 months',
        seniority: 'Junior',
        role: 'Example',
        product: 'Example',
        location: 'Example',
        account: 'Example',
        org2: 'Example',
        org3: 'Example',
        org4: 'Example',
        org5: 'Example'
      },
      {
        planName: 'Plan Name',
        hours: 45,
        items: 18,
        expectedToFinish: '7 months',
        seniority: 'Junior',
        role: 'Example',
        product: 'Example',
        location: 'Example',
        account: 'Example',
        org2: 'Example',
        org3: 'Example',
        org4: 'Example',
        org5: 'Example'
      }
    ];
    return items;
  }

  init(): void {

  }

  close(): void {
    this.searchControl = new UntypedFormControl();
    this.showOpenPlanPopup = false;
    if (this.closeAction.observers.length) {
      this.closeAction.emit();
    }
  }
}
