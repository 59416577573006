<div class="learning-plan-part-wrapper">
  <div class="top-part-wrapper">
    <div class="sub-title-wrapper">
        <!-- <div class="handle">
          <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
            <div popoverOpener>
              <div class="handle-icon"></div>
            </div>
            <div popoverContent class="handle-text">
              {{'learningPlan.part.drag' | amdocsTranslate }}
            </div>
          </lib-amdocs-popover>
        </div> -->

        <div class="handle" *ngIf="!isPreviewMode">
            <div class="handle-icon"></div>
        </div>

        <div class="sub-title"
            *ngIf="!isNameEdining"
        >{{ part.name }}</div>

        <lib-amdocs-input-field
          *ngIf="isNameEdining"
          [elementId]="'part-name-' + part.order"
          [control]="nameControl"
          [hideLabel]="true"
          (blurCallback)="onNameInputBlur()"
          (keyDownCallback)="onNameInputKeyDown($event)"
        ></lib-amdocs-input-field>

        <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'" *ngIf="!isPreviewMode">
          <div popoverOpener>
            <button *ngIf="!isNameEdining" class="toggle-part-rename" (click)="toggleNameInput()">
              <img lib-amdocs-image-version src="/assets/icons/pencil.svg"/>
            </button>
          </div>
          <div popoverContent class="handle-text">
            {{'library.renamePart' | amdocsTranslate }}
          </div>
        </lib-amdocs-popover>

        <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'" *ngIf="!isPreviewMode">
          <div popoverOpener>
            <button class="remove-part" (click)="removeSelf()">
              <img lib-amdocs-image-version src="/assets/icons/trash-can.svg"/>
            </button>
          </div>
          <div popoverContent class="handle-text">
            {{'library.removePart' | amdocsTranslate }}
          </div>
        </lib-amdocs-popover>

    </div>

    <div class="right-side">
      <div class="total-part-duration" *ngIf="!getPartTotalDuration()"><span>0 hr</span></div>
      <div class="total-part-duration" *ngIf="getPartTotalDuration()"><span>{{getPartTotalDuration() | amdocsMinutesToHours:true}}</span></div>
      <button class="add-items" (click)="openLibrary()" *ngIf="!isPreviewMode">
        <img lib-amdocs-image-version src="/assets/icons/plus_icon.svg"/>
        <div>{{'learningPlan.addItems'| amdocsTranslate}}</div>
      </button>
      <button
        class="collapse-part-button"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
      >
        <img *ngIf="!isCollapsed" lib-amdocs-image-version src="/assets/icons/collapsable-up.svg"/>
        <img *ngIf="isCollapsed" lib-amdocs-image-version src="/assets/icons/collapsable-down.svg"/>
      </button>
    </div>

  </div>

  <div [collapse]="isCollapsed" [isAnimated]="true">
    <div class="items-wrapper">
      <div [sortablejs]="part.items" [sortablejsOptions]="sortablejsOptions">
        <ng-container *ngIf="!part.items.length">
          <div class="no-items">{{'learningPlan.emptyPart'| amdocsTranslate}}</div>
        </ng-container>
        <ng-container *ngIf="part.items.length">
          <div *ngFor="let item of part.items; let i = index">
            <app-learning-plan-item [item]="item" [learningPlanType]="LearningPlanType.Create"
                                    [learningTypesList]="learningTypesList"
                                    [isPreviewMode]="isPreviewMode"
                                    (editInLibraryCallback)="editInLibrary($event)"
                                    (removeCallback)="removeItem($event, i)"></app-learning-plan-item>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>
