import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-link-plan-popup',
  templateUrl: './link-plan-popup.component.html',
  styleUrls: ['./link-plan-popup.component.scss'],
})
export class LinkPlanPopupComponent implements OnInit {

  public link: string;
  public showCopyMessage: boolean = false;
  public isTooltipShow: boolean = false;

  public linkFC: UntypedFormControl = new UntypedFormControl();

  @Output() closeAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.linkFC.setValue(this.link);
  }

  copyLink(success: boolean) {
    if (success && !this.isTooltipShow) {
      this.showCopyMessage = true;
      this.isTooltipShow = true;

      setTimeout(() => {
        this.showCopyMessage = false;
        this.isTooltipShow = false; 
      }, 5000);
    }
  }

  close(): void {
    if (this.closeAction.observers.length) {
      this.closeAction.emit();
    }
  }
}
