import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare var axios;
if (true || environment.production) {
  enableProdMode();
}

if (environment.allowMimic && localStorage.getItem('mimic') === '1') {
  const script = document.createElement('script');
  script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/mimic@2.0.9/dist/mimic.js');
  document.head.appendChild(script);
  setTimeout(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }, 1000);
} else {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
axios.defaults.withCredentials = true;
