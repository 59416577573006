<div class="top-banner-wrapper {{customClass}}" [ngStyle]="{'background-image': 'url(/assets/icons/'+ img +')'}" >
  <div class="container">
    <div class="text-wrapper" [ngClass]="{'small-text-width': isSmallTextWidth}">
      <div *ngIf="topSmallText" class="text on-top"> {{text | amdocsTranslate }}
      <span class="text" *ngIf="userName">
        {{userName}},
      </span>
      </div>
      <div class="title label-h0"> {{title | amdocsTranslate }}
      </div>
      <div *ngIf="!topSmallText" class="text"> {{text | amdocsTranslate }}
      </div>
    </div>
  </div>
</div>
