<form [formGroup]="form" class="form-wrapper learning-plan-form">
  <div class="row first-row mb-3">
    <div class="col-6">
      <lib-amdocs-input-field
        [elementId]="'cvName-createPlan'"
        [placeHolder]="'createPlan.form.cvNamePlaceholder' | amdocsTranslate"
        [control]="form.controls.title"
        [label]="'createPlan.form.cvName' | amdocsTranslate"
        [errorsMessages]="{
          required: 'errorsMessages.required' | amdocsTranslate,
          invalid: 'templateLearning.duplicationPopup.title' | amdocsTranslate
        }"
        [inputMaxLength]="150"
        [showMaxLength]="true"
        [tooltipContent]="'createPlan.form.cvNameTooltip' | amdocsTranslate"
        [hasTooltip]="true"
        [isRequired]="true"
      ></lib-amdocs-input-field>
    </div>
    <div class="col-2">
      <lib-amdocs-input-field
        [elementId]="'cvLength-createPlan'"
        [placeHolder]=""
        [control]="form.controls.expectedTime"
        [inputType]="'number'"
        (keyDownCallback)="utils.onlyNumbers($event)"
        [errorsMessages]="{
          required: 'errorsMessages.required' | amdocsTranslate,
          pattern: 'errorsMessages.invalidNumber' | amdocsTranslate,
          greaterThanZero: 'errorsMessages.invalidNumberDecimalFraction' | amdocsTranslate,
          zeroError: 'errorsMessages.invalidNumber' | amdocsTranslate
        }"
        [label]="'createPlan.form.cvLength' | amdocsTranslate"
        [suffixValue]="'createPlan.form.months' | amdocsTranslate"
      ></lib-amdocs-input-field>
    </div>

    <div class="col-2">
      <lib-amdocs-autocomplete
        [label]="'createPlan.form.role' | amdocsTranslate"
        [placeholder]="'createPlan.form.rolePlaceholder' | amdocsTranslate"
        [isMultiple]="true"
        [customClass]="'new-design'"
        [hasHierarchy]="false"
        [hasSearchBox]="true"
        [items]="rolesList"
        [virtualScroll]="false"
        [selectedItemsDisplayPosition]="'top'"
        [clearOnBackspace]="false"
        [selectedItemCode]="form.controls.roleIDs.value"
        (itemChangedCallback)="onChangeRole($event)"
      >
      </lib-amdocs-autocomplete>
    </div>
    <div class="col-2">
      <lib-amdocs-dropdown
        [elementId]="'seniority-createPlan'"
        [newDesign]="true"
        [items]="seniorityList"
        [control]="form.controls.empSeniorityID"
        [placeHolder]="'createPlan.form.seniorityPlaceholder' | amdocsTranslate"
        [label]="'createPlan.form.seniority' | amdocsTranslate"
        [clearable]="true"
      >
      </lib-amdocs-dropdown>
    </div>
  </div>

  <div class="row second-row mb-3">
    <div class="col-8">
      <lib-amdocs-input-field
        [elementId]="'planDescription'"
        [placeHolder]="'createPlan.form.planDescriptionPlaceholder' | amdocsTranslate"
        [control]="form.controls.description"
        [label]="'createPlan.form.planDescription' | amdocsTranslate"
        [inputMaxLength]="350"
        [showMaxLength]="true"
      ></lib-amdocs-input-field>
    </div>
    <div class="col-4">
      <lib-amdocs-input-field
        [elementId]="'prerequisites'"
        [placeHolder]="'createPlan.form.prerequisitesPlaceholder' | amdocsTranslate"
        [control]="form.controls.prerequisites"
        [label]="'createPlan.form.prerequisites' | amdocsTranslate"
        [inputMaxLength]="50"
        [showMaxLength]="true"
      ></lib-amdocs-input-field>
    </div>
  </div>

  <div class="row third-row mb-3">
    <div class="col-3">
      <lib-amdocs-autocomplete
        [items]="org2List"
        [customClass]="'new-design'"
        [selectedItemCode]="form.controls.org2ID.value"
        [placeholder]="'createPlan.form.orgPlaceholder' | amdocsTranslate"
        [label]="'createPlan.form.org2' | amdocsTranslate"
        [hasSearchBox]="true"
        [hasTooltip]="true"
        [tooltipContent]="'createPlan.form.org2Tooltip' | amdocsTranslate"
        (itemChangedCallback)="onChangeOrg2($event)"
        (selectedItemCleared)="clearOrgValuesFromIndex(2)"
      ></lib-amdocs-autocomplete>
    </div>
    <div class="col-3">
      <lib-amdocs-autocomplete
        [items]="org3List"
        [customClass]="'new-design'"
        [selectedItemCode]="form.controls.org3ID.value"
        [placeholder]="'createPlan.form.orgPlaceholder' | amdocsTranslate"
        [label]="'createPlan.form.org3' | amdocsTranslate"
        [readOnly]="!org3List.length"
        [hasSearchBox]="true"
        (itemChangedCallback)="onChangeOrg3($event)"
        (selectedItemCleared)="clearOrgValuesFromIndex(3)"
      ></lib-amdocs-autocomplete>
    </div>
    <div class="col-3">
      <lib-amdocs-autocomplete
        [items]="org4List"
        customClass="new-design"
        [selectedItemCode]="form.controls.org4ID.value"
        [placeholder]="'createPlan.form.orgPlaceholder' | amdocsTranslate"
        [label]="'createPlan.form.org4' | amdocsTranslate"
        [readOnly]="!org4List.length"
        [hasSearchBox]="true"
        (itemChangedCallback)="onChangeOrg4($event)"
        (selectedItemCleared)="clearOrgValuesFromIndex(4)"
      ></lib-amdocs-autocomplete>
    </div>
    <div class="col-3">
      <lib-amdocs-autocomplete
        [items]="org5List"
        [customClass]="'new-design'"
        [selectedItemCode]="form.controls.org5ID.value"
        [placeholder]="'createPlan.form.orgPlaceholder' | amdocsTranslate"
        [label]="'createPlan.form.org5' | amdocsTranslate"
        [readOnly]="!org5List.length"
        [hasSearchBox]="true"
        (itemChangedCallback)="onChangeOrg5($event)"
        (selectedItemCleared)="clearOrgValuesFromIndex(5)"
      ></lib-amdocs-autocomplete>
    </div>
  </div>

  <div class="row fourth-row">
    <div class="col-6">
      <lib-amdocs-autocomplete
        [label]="'createPlan.form.product' | amdocsTranslate"
        [placeholder]="'createPlan.form.productPlaceholder' | amdocsTranslate"
        [isMultiple]="true"
        [customClass]="'new-design'"
        [hasHierarchy]="false"
        [items]="productsList"
        [tooltipContent]="'createPlan.form.productPracticeTooltip' | amdocsTranslate"
        [hasTooltip]="true"
        [selectedItemCode]="form.controls.productIDs.value"
        (itemChangedCallback)="onChangeProduct($event)"
        [noDropdown]="true"
        (selectOpen)="openModalProductPractice()"
      >
      </lib-amdocs-autocomplete>
    </div>
    <div class="col-3">
      <lib-amdocs-autocomplete
        [label]="'createPlan.form.account' | amdocsTranslate"
        [placeholder]="'createPlan.form.accountPlaceholder' | amdocsTranslate"
        [isMultiple]="true"
        [customClass]="'new-design'"
        [hasHierarchy]="false"
        [hasSearchBox]="true"
        [items]="accountsList"
        [selectedItemCode]="form.controls.accountIDs.value"
        (itemChangedCallback)="onChangeAccount($event)"
      >
      </lib-amdocs-autocomplete>
    </div>

    <div class="col-3">
      <lib-amdocs-autocomplete
        [label]="'createPlan.form.location' | amdocsTranslate"
        [placeholder]="'createPlan.form.locationPlaceholder' | amdocsTranslate"
        [isMultiple]="true"
        [customClass]="'new-design'"
        [hasSearchBox]="true"
        [hasHierarchy]="false"
        [items]="locationsList"
        [selectedItemCode]="form.controls.locationIDs.value"
        (itemChangedCallback)="onChangeLocation($event)"
      >
      </lib-amdocs-autocomplete>
    </div>
  </div>
</form>

<div class="text-right mt-4" *ngIf="showSubmit">
  <button
    [disabled]="form.controls.title.invalid"
    class="btn btn-medium dark"
    (click)="planSubmit()"
  >
    <ng-content></ng-content>
  </button>
</div>
