<div id="main-app-wrapper">
  <app-header></app-header>
  <app-page-title *ngIf="pageTitle" [pageTitle]="pageTitle"></app-page-title>
  <lib-amdocs-mimic *ngIf="allowMimic"></lib-amdocs-mimic>
  <ng-container *ngIf="appFinishedLoaded">
    <div class="main-content-wrapper" [ngClass]="{'pageTitle': pageTitle}">
      <div class="container-wrapper" [ngClass]="{'container': pageTitle}">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </ng-container>
  <lib-amdocs-app-loader *ngIf="showLoaderCount" [showOverlay]="true"></lib-amdocs-app-loader>
  <lib-amdocs-version-alert></lib-amdocs-version-alert>
</div>
