<div class="modal-header">
  <span class="label-h1">{{ title }}</span>
  <div class="close-popup-button" role="button" (click)="bsModalRef.hide()" >
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>
<div class="modal-body">
  <div class="content">
    <h3>{{header}}</h3>
    <img *ngIf="icon" lib-amdocs-image-version class="button-icon icon-before mb-4" [src]="'../../../../assets/' + icon"/>
    <p>{{text}}</p>
  </div>
  <div class="buttons-wrapper">

    <!--  Will be used in other scenarios, not in scope -->
    <!-- <button class="btn secondary btn-medium" *ngIf="yesBtnText"><div class="text">{{ yesBtnText }}</div></button> -->
    <!-- <button class="btn secondary btn-medium" *ngIf="noBtnText"><div class="text">{{ noBtnText }}</div></button> -->
    <button
      *ngIf="cancelBtnText"
      [ngClass]="{'large-button': isLargeButton}"
      class="btn secondary btn-medium"
      (click)="bsModalRef.hide()">
      <div class="text">{{ cancelBtnText }}</div>
    </button>

    <button
    *ngIf="actionBtnText"
    class="btn btn-medium dark ml-4"
    (click)="onAction(actionValue)">
    <div class="text">{{ actionBtnText }}</div>
  </button>
  </div>
</div>
