import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IFormControlErrors } from 'amdocs-core-package';
import { distinctUntilChanged } from 'rxjs/operators';
import { CreateLearningPlanService } from 'src/app/feature/create-learning-plan/create-learning-plan.service';
import { ProductPractice } from 'src/app/models/learningPlan';

@Component({
  selector: 'app-product-practice-popup',
  templateUrl: './product-practice-popup.component.html',
  styleUrls: ['./product-practice-popup.component.scss'],
})
export class ProductPracticePopupComponent implements OnInit, OnDestroy {
  @Output() closeCallback: EventEmitter<any> = new EventEmitter();

  public selectedManaged: string[] = [];
  public selectedCustom: string[] = [];

  public managed: ProductPractice[];
  public custom: ProductPractice[];

  public title: string;
  public closeBtnName: string;
  public cancelBtnText: string;
  public header: string;
  public text: string;
  public isLargeButton: boolean;
  public searchControl: UntypedFormControl;
  public selectedOnlyCheckboxCtrl: UntypedFormControl;
  public newLingoNameControl: UntypedFormControl;
  public searchString: string = '';
  public showActiveOnly: boolean;
  public officialNamesList: ProductPractice[] = [];
  public lingoList: ProductPractice[] = [];
  public showAddnewLingoForm: boolean;

  private updatedProductIDs: string[];
  private updatedCustomProductIDs: string[];

  public updateCustomProducts: Function;
  public updateProductsSelection: Function;

  public lingoNameErrors: IFormControlErrors = {
    corectPattern: 'The name you entered is not valid',
    required: 'Name can not be empty',
  };

  @ViewChild('lingoScrollableList') private lingoScrollableList: ElementRef;

  constructor(
    public bsModalRef: BsModalRef,
    private createPlanService: CreateLearningPlanService
  ) {}

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl();
    this.selectedOnlyCheckboxCtrl = new UntypedFormControl();
    this.newLingoNameControl = new UntypedFormControl();
    this.newLingoNameControl.setValidators([
      Validators.required,
      this.lingoTitleValidator(),
    ]);

    this.updatedProductIDs = [...this.selectedManaged];
    this.updatedCustomProductIDs = [...this.selectedCustom];

    this.searchControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((searchText) => {
        this.searchString = searchText;
      });

    this.selectedOnlyCheckboxCtrl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((isChecked) => {
        if (isChecked) {
          this.clearSearchInput();
          this.searchControl.disable();
          this.showActiveOnly = true;
        } else {
          this.searchControl.enable();
          this.showActiveOnly = false;
        }
      });

    if (this.managed && this.custom) {
      this.updateLists(this.managed, this.custom);
    } else {
      this.loadProducts();
    }

    this.newLingoNameControl.valueChanges.subscribe((value) => {
      if (
        value.charCodeAt(0) === 32 ||
        value.charCodeAt(value.length - 1) === 32
      ) {
        this.newLingoNameControl.setValue(value.trim());
      }
    });
  }

  private lingoTitleValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const passTest =
        /((|)((?=.*[a-zA-Z]).*|(?=[A-Za-z0-9@#$%^&+!=\/\\\[\].,(){}*]+$)^(?=.*[0-9])(?=.*[@#$%^&+!=\/\\\[\].,(){}*]).*))+/g.test(
          value
        );

      return !passTest ? { corectPattern: true } : null;
    };
  }

  private loadProducts(): void {
    this.createPlanService.getProducts().subscribe((res) => {
      this.updateLists(res.products.managed, res.products.custom);
    });
  }

  private updateLists(
    managed: ProductPractice[],
    custom: ProductPractice[]
  ): void {
    this.officialNamesList = managed;
    this.lingoList = custom;

    this.officialNamesList.forEach(
      (item) =>
        (item.selected = this.updatedProductIDs.indexOf(item.code) !== -1)
    );
    this.lingoList.forEach(
      (item) =>
        (item.selected = this.updatedCustomProductIDs.indexOf(item.code) !== -1)
    );
  }

  ngOnDestroy(): void {
    if (this.updateProductsSelection) {
      this.updateProductsSelection(
        this.updatedProductIDs,
        this.updatedCustomProductIDs
      );
    }
  }

  clearSearchInput(): void {
    if (!this.searchControl.disabled) {
      this.searchControl.setValue('');
    }
  }

  getSelectedList(
    list: ProductPractice[],
    isSelectedOnly: boolean
  ): ProductPractice[] {
    if (isSelectedOnly) {
      return list.filter((item) => item.selected);
    }
    return list;
  }

  onOfficialNameCheckChanged(item: ProductPractice): void {
    if (item.selected) {
      this.updatedProductIDs.push(item.code);
    } else {
      this.updatedProductIDs = this.updatedProductIDs.filter(
        (code) => code !== item.code
      );
    }
  }

  onLingoCheckChanged(item: ProductPractice): void {
    if (item.selected) {
      this.updatedCustomProductIDs.push(item.code);
    } else {
      this.updatedCustomProductIDs = this.updatedCustomProductIDs.filter(
        (code) => code !== item.code
      );
    }
  }

  toggleAddNewLingo(): void {
    this.showAddnewLingoForm = !this.showAddnewLingoForm;
  }

  addNewLingo(): void {
    this.newLingoNameControl.markAsDirty();
    if (this.newLingoNameControl.invalid) {
      return;
    }

    this.searchControl.setValue('');
    const name = this.newLingoNameControl.value;
    this.newLingoNameControl.setValue('');
    this.newLingoNameControl.markAsPristine();
    this.lingoScrollableList.nativeElement.scrollTop = 0;
    this.createPlanService.addCustomProduct({ name }).subscribe((res) => {
      const code = res.addCustomProduct.itemCode;
      if (code) {
        const newItem = { code, name, selected: true };
        this.lingoList = [newItem, ...this.lingoList];
        this.updateCustomProducts(this.lingoList);
        this.onLingoCheckChanged(newItem);
      }
    });
  }
}
