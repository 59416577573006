import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmdocsUiModule } from 'amdocs-core-package';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LearningPlanComponent } from './components/learning-plan/learning-plan.component';
import { LearningPlanPartComponent } from './components/learning-plan-part/learning-plan-part.component';
import { LearningPlanItemComponent } from './components/learning-plan-item/learning-plan-item.component';
import { LearningPlanItemFormComponent } from './components/learning-pan-item-form/learning-plan-item-form.component';
import { LibraryComponent } from './components/library/library.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { OpenPlanPopupComponent } from './components/open-plan-popup/open-plan-popup.component';
import { PlanItemComponent } from './components/plan-item/plan-item.component';
import { LearningTemplatesListComponent } from './components/learning-templates-list/learning-templates-list.component';
import { LearningTemplateComponent } from './components/learning-template/learning-template.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SortableModule, DraggableItemService } from 'ngx-bootstrap/sortable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LearningPlanFormComponent } from './components/learning-plan-form/learning-plan-form.component';
import { ActionPopupComponent } from './components/action-popup/action-popup.component';
import { ProductPracticeCheckboxComponent } from './components/product-practice-checkbox-item/product-practice-checkbox-item.component';
import { ProductPracticePopupComponent } from './components/product-practice-popup/product-practice-popup.component';
import { SelectedItemsPopupComponent } from './components/selected-items-popup/selected-items-popup.component';
import { CopyExistingPlanComponent } from './components/copy-existing-plan-popup/copy-existing-plan-popup.component';
import { CopyExistingService } from './services/copy-existing.service';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { PreviewPlanComponent } from './components/preview-plan-popup/preview-plan-popup.component';
import { PreviewPlanService } from './services/preview-plan.service';
import { IncludedItemsPopupComponent } from './components/included-items-popup/included-items-popup.component';
import { PdfGeneratorService } from './services/pdf-generator.service';
import { LinkPlanPopupComponent } from './components/link-plan-popup/link-plan-popup.component';


const shareComponents = [
  HeaderComponent,
  PageTitleComponent,
  TopBannerComponent,
  FooterComponent,
  LearningPlanComponent,
  LearningPlanPartComponent,
  LearningPlanItemComponent,
  LibraryComponent,
  PaginatorComponent,
  LearningPlanItemFormComponent,
  OpenPlanPopupComponent,
  PlanItemComponent,
  LearningTemplatesListComponent,
  LearningTemplateComponent,
  LearningPlanFormComponent,
  ActionPopupComponent,
  ProductPracticeCheckboxComponent,
  ProductPracticePopupComponent,
  SelectedItemsPopupComponent,
  CopyExistingPlanComponent,
  PreviewPlanComponent,
  IncludedItemsPopupComponent,
  LinkPlanPopupComponent
];

@NgModule({
  declarations: [...shareComponents],
  imports: [
    AmdocsUiModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    SortablejsModule,
    SortableModule,
    ClipboardModule
  ],
  providers: [DraggableItemService, CopyExistingService, PreviewPlanService, PdfGeneratorService],
  exports: [AmdocsUiModule, shareComponents],
})
export class ShareModule { }
