import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {
  AmdocsAppInitResponse,
  AmdocsAppInitService,
  AmdocsTranslateService,
  IAppGatewayConfig,
  IAppSyncConfig,
  ILoginGatewayConfig,
} from 'amdocs-core-package';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { RouterModule } from '@angular/router';
import { ShareModule } from './share/share.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { finalize, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from './core/user.service';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';

export function initializeApp(
  appInitService: AmdocsAppInitService,
  translateService: AmdocsTranslateService,
  user: UserService
): any {
  const appInit = appInitService;
  // const user = UserService;

  return (): Promise<any> => {
    return translateService
      .loadTranslate()
      .pipe(
        mergeMap(() => {
          return appInitService.initApp().pipe(
            mergeMap((appInitResponse: AmdocsAppInitResponse) => {
              if (appInitResponse.success || true) {
                // remove true
                user.setUserDetails();
                return of(true); // TODO :: init app manager hub specific
              } else {
                if (!appInit.appInitError) {
                  appInit.appInitError = appInitResponse.error;
                }
              }
              return of(appInitResponse);
            }),
            finalize(() => {
              console.log('finalize init');
              appInit.appFinishedLoaded = true;
              document.body.className = document.body.className.replace(
                'show-splash',
                ''
              );
            })
          );
        })
      )
      .toPromise();
  };
}

const appSyncConfig: IAppSyncConfig = {
  url: environment.graphQlBaseURL,
  region: 'us-east-1',
  initAppOperationName: '',
};

const appGatewayConfig: IAppGatewayConfig = {
  url: 'https://localhost:4200/appConfig',
  region: 'us-east-1',
};

const loginGatewayConfig: ILoginGatewayConfig = {
  url: environment.loginGWBaseURL,
  region: 'eu-west-1',
  appid: environment.appId,
  refreshTokenInterval: environment.refreshTokenInterval,
};

const imageVersion = environment.imgVersion;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ShareModule,
    CoreModule,
    RouterModule,
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AmdocsAppInitService, AmdocsTranslateService, UserService],
      multi: true,
    },
    {
      provide: 'IAppSyncConfig',
      useValue: appSyncConfig,
    },
    {
      provide: 'IAppGatewayConfig',
      useValue: appGatewayConfig,
    },
    {
      provide: 'ILoginGatewayConfig',
      useValue: loginGatewayConfig,
    },
    {
      provide: 'IImageVersion',
      useValue: imageVersion,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
