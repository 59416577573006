<div class="modal-header">
  <span class="label-h1">{{ title }}</span>
  <div
    class="close-popup-button"
    role="button"
    (click)="bsModalRef.hide()"
  >
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>
<div class="modal-body">
  <div class="list-wrapper">
    <div
      class="card mt-2 mb-3"
      *ngFor="let template of selectedItems"
    >
      <app-learning-template
        class="included-existing-plans"
        [template]="template"
        [showAdditionalBlock]="false"
        [truncateRequired]="false"
      ></app-learning-template>
    </div>

    <amdocs-list-paginator
      *ngIf="totalRows > pageSize && !showLoader"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [totalRows]="totalRows"
      (onPageChange)="onPageChange($event)"
    >
    </amdocs-list-paginator>
  </div>

</div>

<lib-amdocs-app-loader *ngIf="showLoader"></lib-amdocs-app-loader>