import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { LearningPlanType, LearningItem } from '../../../models/learningPlan'
import { LibraryItemsSelectionsService } from '../../services/libraryItemsSelections.service'

@Component({
  selector: 'app-selected-items-popup',
  templateUrl: './selected-items-popup.component.html',
  styleUrls: ['./selected-items-popup.component.scss']
})
export class SelectedItemsPopupComponent implements OnInit, OnDestroy {
  @Output() closeCallback: EventEmitter<any> = new EventEmitter()

  public LearningPlanType = LearningPlanType

  public title: string
  public closeBtnName: string
  public cancelBtnText: string
  public header: string
  public text: string

  public selectedItems = []

  constructor(
    public bsModalRef: BsModalRef,
    private libraryItemsSelectionsService: LibraryItemsSelectionsService,
  ) {
    this.libraryItemsSelectionsService.selectedLibraryItemsSelector.subscribe(items => {
      this.selectedItems = items
    })
  }

  removeSelection(code: LearningItem["code"]): void {
    this.libraryItemsSelectionsService.removeSelection(code)
    if(!this.selectedItems.length) {
      this.bsModalRef.hide()
    }
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {

  }

}
