<div class="mh-popup-header">
  <span class="label-h1">Link to published plan</span>
  <div
    class="close-popup-button"
    role="button"
    (click)="bsModalRef.hide()"
  >
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>

<div class="modal-body d-flex flex-column justify-content-center mb-5">
  <h3 class="mt-4">Link for the published plan available to learner:</h3>


  <div class="link-wrapper d-flex justify-content-center align-items-center mt-5 mb-5">
    <div class="col-7">
      <lib-amdocs-input-field
        [elementId]="'planLink'"
        [hideLabel]="true"
        [placeHolder]="'Plan link'"
        [control]="linkFC"
        [readOnly]="true"
      >
      </lib-amdocs-input-field>
    </div>

    <button
      type="button"
      class="btn btn-small secondary btn-outline copy-btn"
      [cdkCopyToClipboard]="linkFC.value"
      (cdkCopyToClipboardCopied)="copyLink($event)"
    >

      {{ showCopyMessage ? 'Copied to clipboard' : 'Copy link' }}
    </button>
  </div>

</div>