import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UtilsService } from '../../../core/utils.service';
import { Router } from '@angular/router';
import { CONSTANTS } from 'src/app/constants';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  takeUntil,
} from 'rxjs/operators';
import { SortableTemplatesStatuses } from 'src/app/models/learningTemplate';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CopyExistingService } from '../../services/copy-existing.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
  selector: 'app-copy-existing-plan-popup',
  templateUrl: './copy-existing-plan-popup.component.html',
  styleUrls: ['./copy-existing-plan-popup.component.scss'],
})
export class CopyExistingPlanComponent implements OnInit, OnDestroy {
  public existingPlans;

  @ViewChild('pagination') pagination: PaginatorComponent;

  @Output() closeAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  public pageSize = 10;
  public pageNumber = 1;
  public totalRows: number;
  private firstPageOffset = 0;
  private startOffset: number = 0;
  public searchFC: UntypedFormControl = new UntypedFormControl();
  private unsubscribe$ = new Subject();

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private copyExistingService: CopyExistingService,
    private eventBus: AmdocsEventBusService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.init();
  }

  get utils(): UtilsService {
    return this.utilsService;
  }

  init(): void {
    this.searchFC.valueChanges
      .pipe(
        debounceTime(400),
        filter((searchText) => searchText?.length >= 3 || !searchText),
        distinctUntilChanged()
      )
      .subscribe((searchText) =>
        this.searchPlanForCopy(searchText, this.firstPageOffset, true)
      );
    this.searchPlanForCopy();
  }

  searchPlanForCopy(queryPhrase?, startOffset?, isSetFirstPage = false): void {
    const params = {
      input: {
        queryPhrase: queryPhrase || this.searchFC.value || '',
        createdBy: SortableTemplatesStatuses.All.toLocaleLowerCase(),
        size: this.pageSize,
        start: startOffset ?? this.startOffset,
      },
    };

    this.getExistingPlans(params, isSetFirstPage);
  }

  getExistingPlans(params, isSetFirstPage): void {
    this.copyExistingService
      .getExistingPlans(params)
      .pipe(
        finalize(() => {}),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (templatesLibrary) => {
          this.existingPlans = templatesLibrary.templates;
          this.totalRows = templatesLibrary.found;
          if (isSetFirstPage) {
            this.pageNumber = 1;
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
  }

  makeCopy(code): void {
    this.bsModalRef.hide();
    this.router.navigate([`/learning-plan/copy/${code}`]);
  }

  onPageChange(page): void {
    if (page === 1) {
      this.startOffset = 0;
    }

    this.startOffset = (page - 1) * this.pageSize;
    this.pageNumber = page;
    this.searchPlanForCopy();
  }

  close(): void {
    this.searchFC = new UntypedFormControl();
    if (this.closeAction.observers.length) {
      this.closeAction.emit();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
