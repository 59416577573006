import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmdocsInputFieldComponent } from 'amdocs-core-package';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  LearningItem,
  LearningPlanPart,
  LearningPlanType,
} from 'src/app/models/learningPlan';

@Component({
  selector: 'app-learning-plan-part',
  templateUrl: './learning-plan-part.component.html',
  styleUrls: ['./learning-plan-part.component.scss'],
})
export class LearningPlanPartComponent implements OnInit {
  @Input() part: LearningPlanPart;
  @Input() learningTypesList = [];
  @Input() isPreviewMode: boolean = false;
  @Output() editItemCallback: EventEmitter<any> = new EventEmitter();
  @Output() openLibraryCallback: EventEmitter<any> = new EventEmitter();
  @Output() removeItemCallback: EventEmitter<any> = new EventEmitter();
  @Output() removeCallback: EventEmitter<any> = new EventEmitter();
  @Output() renameCallback: EventEmitter<any> = new EventEmitter();
  @Output() editInLibraryCallback: EventEmitter<any> = new EventEmitter();

  public nameControl: UntypedFormControl;
  public isNameEdining = false;
  public isCollapsed = false;

  private preEditName = '';

  constructor() {}

  // ToDo: remove leftovers of legacy design
  public LearningPlanType = LearningPlanType;

  public sortablejsOptions: any = {
    group: 'Items',
    handle: '.handle',
    onEnd: (end) => {
      this.onMove(end);
    },
  };

  ngOnInit(): void {
    this.nameControl = new UntypedFormControl();
    this.nameControl.setValue(this.part.name);
    this.nameControl.valueChanges
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(1000))
      .subscribe((name) => {
        this.part.name = name;
        this.renameSelf();
      });
  }

  onMove(evt = null): void {
    this.editItemCallback.emit();
  }

  openLibrary(): void {
    this.openLibraryCallback.emit();
  }

  editInLibrary($event): void {
    this.editInLibraryCallback.emit($event);
  }

  removeItem($event, i: number): void {
    this.removeItemCallback.emit({ item: $event, itemIndex: i });
  }

  renameSelf(): void {
    this.renameCallback.emit();
  }

  removeSelf(): void {
    this.removeCallback.emit();
  }

  onNameInputBlur(): void {
    this.toggleNameInput();

    // ToDo: Update on API if value changed
  }

  onNameInputKeyDown(key): void {
    // ToDo: This direct intrusion is used due to inconvenient UI library input component which ruins the default interface of input field.
    const nameInput = <HTMLInputElement>(
      document.getElementById('part-name-' + this.part.order)
    );
    switch (key.code) {
      case 'Enter':
        {
          nameInput.blur();
        }
        break;
      case 'Escape':
        {
          this.nameControl.setValue(this.preEditName);
          nameInput.blur();
        }
        break;
    }
  }

  toggleNameInput(): void {
    this.preEditName = this.part.name;
    // ToDo: This direct intrusion is used due to inconvenient UI library input component which ruins the default interface of input field.
    this.isNameEdining = !this.isNameEdining;
    if (this.isNameEdining) {
      setTimeout(() => {
        const nameInput = <HTMLInputElement>(
          document.getElementById('part-name-' + this.part.order)
        );
        nameInput.focus();
      }, 0);
    }
  }

  public getPartTotalDuration(): number {
    return this.part.items.reduce(
      (prev, cur) => prev + (cur.activated ? cur.duration : 0),
      0
    );
  }
}
