<div class="plan-item-wrapper">
  <div class="plan-item-zone first">
    <div class="plan-title label-h4">{{item.planName}}</div>
    <div class="plan-details">
      <div class="d-flex">
        <img lib-amdocs-image-version src="/assets/icons/course-time.svg"/>
        <div class="details-text"> {{ 'planItem.expectedToFinish' | amdocsTranslate:[item.hours] }}</div>
      </div>
      <div class="d-flex">
        <img lib-amdocs-image-version src="/assets/icons/course-clock.svg"/>
        <div class="details-text">{{ 'planItem.items' | amdocsTranslate:[item.items] }}</div>
      </div>

    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.expectedToFinish' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.expectedToFinish}}</span>
    </div>
  </div>
  <div class="plan-item-zone">
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.seniority' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.seniotiry}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.role' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.role}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.product' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.product}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.location' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.location}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.account' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.account}}</span>
    </div>
  </div>
  <div class="plan-item-zone">
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.org2' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.org2}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.org3' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.org3}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.org4' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.org4}}</span>
    </div>
    <div class="plan-info-line">
      <span class="plan-label">{{ 'planItem.org5' | amdocsTranslate }}</span>
      <span class="plan-value">{{item.org5}}</span>
    </div>
  </div>
</div>
