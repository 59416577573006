import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LearningPlanType } from '../../../models/learningPlan';
import { IncludedPlanItemsService } from '../../services/includedPlanItems.service';

@Component({
  selector: 'app-included-items-popup',
  templateUrl: './included-items-popup.component.html',
  styleUrls: ['./included-items-popup.component.scss']
})
export class IncludedItemsPopupComponent implements OnInit, OnDestroy {
  @Output() closeCallback: EventEmitter<any> = new EventEmitter();

  public LearningPlanType = LearningPlanType;

  public title: string;
  public closeBtnName: string;
  public cancelBtnText: string;
  public header: string;
  public text: string;
  public totalRows: number;
  public pageSize = 10;
  public pageNumber = 1;
  public showLoader = false;

  public selectedItems = [];
  public includedItemsIds = [];
  public includedBatches: any;
  private chunkSize = 10;
  private cachedPlans: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private includedPlanItemsService: IncludedPlanItemsService
  ) {}

  ngOnInit(): void {
    this.totalRows = this.includedItemsIds.length;
    this.includedBatches = this.splitArray(
      this.includedItemsIds,
      this.chunkSize
    );
    this.fetchTemplates(this.includedBatches[this.pageNumber - 1]);
  }

  private fetchTemplates(codes: string[]): void {
    const params = {
      input: {
        codes,
        shouldFilterInactiveItems: true
      }
    };

    this.showLoader = true;
    this.includedPlanItemsService.getTemplates(params)
      .subscribe({
        next: plans => {
          const remappedPlans = plans.map(({ code, latest }) => ({
            code,
            ...latest.templatePlanDetails
          }));

          this.selectedItems = [...remappedPlans];
          this.cachedPlans[this.pageNumber - 1] = remappedPlans;
          this.showLoader = false;
        },
        error: err => {
          this.showLoader = false;
        }
      });
  }

  onPageChange(page: number): void {
    this.pageNumber = page;

    if (this.cachedPlans && this.cachedPlans[this.pageNumber - 1]) {
      this.selectedItems = [...this.cachedPlans[this.pageNumber - 1]];
    } else {
      this.fetchTemplates(this.includedBatches[this.pageNumber - 1]);
    }
  }

  private splitArray(arr: string[], chunkSize: number): string[][] {
    const result: string[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  ngOnDestroy(): void {
    this.closeCallback.emit();
  }
}
