import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class IncludedPlanItemsService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getTemplates(params): Observable<any> {
    return this.apiService
      .GetTemplates(params)
      .pipe(map((response) => response.templates));
  }
}
