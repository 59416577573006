<div id="nav-main-wrapper">
  <div class="container">
    <div class="header-row row">
      <div class="header-section col-4">
        <div class="logo d-flex align-items-center">
          <img
            lib-amdocs-image-version
            src="/assets/icons/expert-view-logo-ext.svg"
            (click)="goHome()"
          />
        </div>
      </div>
      <div class="header-section col-8 right">
        <div class="menu d-flex align-items-center">
          <ng-container *ngFor="let item of menuItems">
            <div
              *ngIf="item.action"
              class="text-small-normal menu-item pointer"
              (click)="onActionClick(item)"
            >
              <span class="item-desc label-h3">{{ item.name }}</span>
            </div>
            <lib-amdocs-popover
              #popOver
              class="dropdown"
              *ngIf="!item.action"
              [trigger]="'click'"
              [placement]="'bottom right'"
            >
              <div popoverOpener>
                <div class="user-name pointer label-h3">
                  {{ "header.menuItems.create.label" | amdocsTranslate }}
                </div>
              </div>
              <div popoverContent>
                <div class="label-h3 d-flex flex-column">
                  <a class="item-desc label-h3 d-flex align-items-center" routerLink="learning-plan" (click)="hidePopOver()">
                    <img
                      lib-amdocs-image-version
                      class="mr-3"
                      src="/assets/icons/file-default.svg"
                      [alt]="
                        'header.menuItems.create.createPlan' | amdocsTranslate
                      "
                    />
                    {{ "header.menuItems.create.createPlan" | amdocsTranslate }}
                  </a>
                  <a class="item-desc label-h3 d-flex align-items-center" (click)="showCopyModal()">
                    <img
                      lib-amdocs-image-version
                      class="mr-3"
                      src="/assets/icons/file-copy.svg"
                      [alt]="
                        'header.menuItems.create.copyPlan' | amdocsTranslate
                      "
                    />
                    {{ "header.menuItems.create.copyPlan" | amdocsTranslate }}
                  </a>
                </div>
              </div>
            </lib-amdocs-popover>
          </ng-container>
        </div>
        <div class="separation-line"></div>
        <div class="logout">
          <lib-amdocs-popover [trigger]="'click'" [placement]="'bottom right'">
            <div popoverOpener>
              <div class="user-name label-h3" *ngIf="!isMobileSize()">
                {{ name }}
              </div>
              <div class="user-name label-h3" *ngIf="isMobileSize()">
                {{ getUserShortName(name) }}
              </div>
            </div>
            <div popoverContent>
              <div class="name-wrapper label-h3">
                <span>{{ name }}</span>
              </div>
              <div class="separator"></div>
              <button
                class="link-item btn btn-logout label-h5"
                (click)="onLogout()"
              >
                {{ "common.logout" | amdocsTranslate }}
              </button>
            </div>
          </lib-amdocs-popover>
        </div>
      </div>
    </div>
  </div>
</div>

<app-open-plan-popup
  [showOpenPlanPopup]="showOpenPlanPopup"
  (closeAction)="showOpenPlanPopup = false"
>
</app-open-plan-popup>

<app-library
  [showLibrary]="showItemsLibrary"
  [selectableItems]="false"
  (closeCallback)="showItemsLibrary = false"
></app-library>
