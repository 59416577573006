<div class="paginator d-flex justify-content-center" *ngIf="lastPage">
    <div class="d-flex">
      <div class="next-prev-wrapper d-flex align-items-center">
        <div [disabled]="!allowPrev" class="prev" href="javascript:void(0)" (click)="prev()">
          <div class="icon icon-right" [ngClass]="{'disable': pageNumber === 1}"></div>
        </div>
      </div>
      <div class="current-page-wrapper d-flex">
        
        <ng-container
          *ngIf="lastPage <= 10"
          [ngTemplateOutlet]="fixedPages"
        ></ng-container>

        <ng-container
          *ngIf="lastPage > 10"
          [ngTemplateOutlet]="lensPages"
        ></ng-container>

      </div>
      <div class="next-prev-wrapper d-flex align-items-center">
        <div [disabled]="!allowNext" class="next" href="javascript:void(0)" (click)="next()">
          <div class="icon icon-left" [ngClass]="{'disable': pageNumber === lastPage}"></div>
        </div>
      </div>
    </div>
</div>

<ng-template #fixedPages>
  <ng-container *ngFor="let page of pages; let i = index; trackBy: trackByMethod">
    <ng-container *ngIf="i < 10">
      <button class="current-page-btn" [ngClass]="{'selected': pageNumber === page.value}" (click)="selectPage(page.value)">{{page.value}}</button>
    </ng-container>
    <ng-container *ngIf="pageNumber > 10 && pageNumber < lastPage && pageNumber === page.value">
      <img lib-amdocs-image-version class="triple-dots" src="/assets/icons/triple-dots.svg"/>
      <button class="current-page-btn" [ngClass]="{'selected': pageNumber === page.value }" (click)="selectPage()">{{page.value}}</button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="lastPage > 10">
    <img lib-amdocs-image-version class="triple-dots" src="/assets/icons/triple-dots.svg"/>
    <button class="current-page-btn" [ngClass]="{'selected': pageNumber === lastPage }" (click)="selectPage()">{{lastPage}}</button>
  </ng-container>
</ng-template>

<ng-template #lensPages>
  <button class="current-page-btn" [ngClass]="{'selected': pageNumber === 1}" (click)="selectPage(1)">1</button>
  <img lib-amdocs-image-version class="triple-dots" src="/assets/icons/triple-dots.svg" *ngIf="pageNumber > 5"/>
  <button class="current-page-btn" (click)="selectPage(2)" *ngIf="pageNumber === 5">{{2}}</button>

  <button class="current-page-btn" (click)="selectPage(pageNumber-2)" *ngIf="pageNumber > 3">{{pageNumber-2}}</button>
  <button class="current-page-btn" (click)="selectPage(pageNumber-1)" *ngIf="pageNumber > 2">{{pageNumber-1}}</button>
  <button class="current-page-btn selected" (click)="selectPage()" *ngIf="pageNumber > 1">{{pageNumber}}</button>
  <button class="current-page-btn" (click)="selectPage(pageNumber+1)" *ngIf="pageNumber < (lastPage-1)">{{pageNumber+1}}</button>
  <button class="current-page-btn" (click)="selectPage(pageNumber+2)" *ngIf="pageNumber < (lastPage-2)">{{pageNumber+2}}</button>

  <button class="current-page-btn" (click)="selectPage(pageNumber+3)" *ngIf="pageNumber === (lastPage-4)">{{pageNumber+3}}</button>
  <img lib-amdocs-image-version class="triple-dots" src="/assets/icons/triple-dots.svg" *ngIf="pageNumber < (lastPage-4)"/>
  <button class="current-page-btn" [ngClass]="{'selected': pageNumber === lastPage }" (click)="selectPage(lastPage)" *ngIf="pageNumber < lastPage">{{lastPage}}</button>
</ng-template>
