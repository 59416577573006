import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { LearningItem, SearchLearningItems } from '../../../models/learningPlan';
import { mergeMap, tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
  ) {
  }

  searchLearningItems(params): Observable<SearchLearningItems> {
    return this.apiService.searchLearningItems(params);
  }

  searchCourses(params): Observable<LearningItem[]> {
    return this.apiService.searchCourses(params);
  }

  // ToDo: This has to be core functionality
  public initCreateNewItemData(): Observable<any> {
    const calls = [
      this.getLearningDomains(),
      this.getLearningTypes()
    ];

    return forkJoin(calls).pipe(
      mergeMap((response: any[]) => {
        const data = {
          learningDomains: response[0],
          learningTypes: response[1]
        };

        return of(data);
      })
    ).pipe(tap(res => {
      this.utilsService.dropdownData.learningDomains = res?.learningDomains?.learningDomains;
      this.utilsService.dropdownData.learningTypes = res?.learningTypes?.learningTypes;
    }));
  }

  getLearningDomains(): Observable<any> {
    return this.apiService.getLearningDomains();
  }

  getLearningTypes(): Observable<any> {
    return this.apiService.getLearningTypes();
  }
}
