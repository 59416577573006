<div class="modal-header">
  <span class="label-h1">{{ title }} ({{selectedItems.length}})</span>
  <div class="close-popup-button" role="button" (click)="bsModalRef.hide()" >
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>
<div class="modal-body">

  <div class="list-wrapper">
    <div class="item-row" *ngFor="let item of selectedItems">
      <app-learning-plan-item 
        [item]="item" 
        [learningPlanType]="LearningPlanType.SelectionListItem"
      ></app-learning-plan-item>
      <div (click)="removeSelection(item.code)" class="remove-item-wrapper">
        <img lib-amdocs-image-version src="/assets/icons/popup-close.svg"/>
      </div>
    </div>
  </div>

</div>