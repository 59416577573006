<div class="library-overlay" *ngIf="showLibrary">
</div>
<div class="library-component" *ngIf="showLibrary">

    <div class="title-wrapper">
        <div class="label-h1">{{'library.title' | amdocsTranslate}}</div>
        <img lib-amdocs-image-version class="pointer" (click)="closeLibrary()" src="/assets/icons/close-overlay.svg" />

    </div>
    <div class="actions-wrapper" *ngIf="!isEditLibraryMode">

        <div class="left-side-wrapper">

            <div class="tabs-wrapper">
                <div class="tab" (click)="setCreatedOrUpdatedByYou(true)" [ngClass]="{'active': createdOrUpdatedByYou}">
                    <span>Edited by you</span>
                </div>
                <div class="separator"></div>
                <div class="tab" (click)="setCreatedOrUpdatedByYou(false)" [ngClass]="{'active': !createdOrUpdatedByYou}">
                    <span>Search in library</span>
                </div>
            </div>

            <div class="search-wrapper">
                <div class="search-input-wrapper" *ngIf="!createdOrUpdatedByYou">
                    <lib-amdocs-input-field [elementId]="'library-search'" [placeHolder]="'library.findAnItem' | amdocsTranslate" [control]="searchControl" [hideLabel]="true"></lib-amdocs-input-field>
                    <img class="find-an-item-icon" lib-amdocs-image-version src="/assets/icons/find-an-item.svg">
                    <img class="clear-input-icon" *ngIf="searchControl.value" (click)="clearSearchInput()" lib-amdocs-image-version src="/assets/icons/clear-input.svg">
                </div>
            </div>
        </div>

        <div class="right-side-wrapper">

            <div class="show-inactive-wrapper d-flex align-items-center">
                <lib-amdocs-switcher [elementId]="'show-inactive'" [control]="showInactiveCtrl" [labelText]="'Show inactive'"></lib-amdocs-switcher>
            </div>

            <ng-container [ngTemplateOutlet]="createNewItemButton">
            </ng-container>
        </div>
    </div>

    <div class="secondary-actions-wrapper" *ngIf="!createdOrUpdatedByYou && !isEditLibraryMode">
        <div class="sort-by-wrapper">
            <div class="sort-by-title">{{'library.sortBy.label'| amdocsTranslate}}</div>
            <div class="sort-by-dd">
                <lib-amdocs-dropdown [elementId]="'library-sort'" [newDesign]="true" [items]="sortList" [control]="sortControl" [hideLabel]="true"></lib-amdocs-dropdown>
            </div>
        </div>
    </div>

    <div class="results-wrapper" [ngClass]="{'with-actions': !createdOrUpdatedByYou, 'empty': !libraryItems.length || (!optimisticRecords.length && createdOrUpdatedByYou), 'with-selection': selectedItems.length}">
        <div class="library-items-wrapper" #libraryItemsWrapper>

            <div class="library-add-item-wrapper" *ngIf="isItemCreationProcess">
                <app-learning-plan-item-form (createItemAction)="createItem($event)" [selectableItems]="selectableItems" (itemsVisibility)="applyItemsVisibility($event)" (checkCallback)="itemCheckChanged($event)" (closeAction)="finishItemCreationProcess()"></app-learning-plan-item-form>
            </div>

            <ng-container *ngIf="optimisticRecords.length && createdOrUpdatedByYou && !hideItems">
                <div *ngFor="let item of optimisticRecords">
                    <app-learning-plan-item [item]="item" [learningPlanType]="LearningPlanType.LibrarySync" [selectable]="selectableItems" (checkCallback)="itemCheckChanged($event)" (editItemCallback)="editItemCallback($event)" [isChecked]="isItemChecked(item.code)" [learningTypesList]="learningTypes"></app-learning-plan-item>
                </div>
            </ng-container>

            <ng-container *ngIf="libraryItems.length && !hideItems && !showLoader">
                <div *ngFor="let item of libraryItems">
                    <app-learning-plan-item [item]="item" [learningPlanType]="LearningPlanType.Library" [selectable]="selectableItems" [editSelectedPlan]="editSelectedPlan" (checkCallback)="itemCheckChanged($event)" [isChecked]="isItemChecked(item.code)" (updateItemAction)="updateItem($event)"
                        (editItemCallback)="editItemCallback($event)" [learningTypesList]="learningTypes" (updateItemActivieStateAction)="updateItemActiveState($event)"></app-learning-plan-item>
                </div>
            </ng-container>

            <app-paginator *ngIf="foundItemsCount > pageSize" [pageSize]="pageSize" [pageNumber]="pageNumber" [totalRows]="foundItemsCount" (onPageChange)="pageChanged($event)">
            </app-paginator>

            <div class="alternative-actions-wrapper" *ngIf="createdOrUpdatedByYou && !libraryItems?.length && !isItemCreationProcess && !showLoader">
                <div class="empty-search-wrapper">
                    <img lib-amdocs-image-version class="pointer" src="/assets/icons/items-search/no_tasks_yet.svg" />
                    <div class="empty-search">{{'library.noTasksYet' | amdocsTranslate}}</div>
                </div>
            </div>

            <div class="alternative-actions-wrapper" *ngIf="!createdOrUpdatedByYou && !libraryItems?.length && !searchControl?.value?.length && !showLoader">
                <div class="empty-search-wrapper">
                    <img lib-amdocs-image-version class="pointer" src="/assets/icons/items-search/search.svg" />
                    <div class="empty-search">{{'library.emptySearch' | amdocsTranslate}}</div>
                </div>
            </div>

            <div class="alternative-actions-wrapper" *ngIf="!createdOrUpdatedByYou && !libraryItems?.length && searchControl?.value?.length && !showLoader">
                <div class="empty-search-wrapper">
                    <img lib-amdocs-image-version class="pointer" src="/assets/icons/items-search/no_results.svg" />
                    <div class="empty-search">{{'library.emptyResults' | amdocsTranslate}}</div>
                </div>
            </div>

        </div>
    </div>
    <div class="footer-wrapper" *ngIf="selectedItems.length">
        <div class="add-to-wrapper">
            <div class="add-part-dd-title">{{'library.addTo'| amdocsTranslate}}</div>
            <div class="add-part-dd">
                <lib-amdocs-dropdown [elementId]="'library-part'" [newDesign]="true" [items]="partsList" [control]="partControl" [isDropup]="true" [label]="''"></lib-amdocs-dropdown>
            </div>
        </div>
        <div class="right-side-wrapper">
            <span class="items-count" (click)="openModalSelectedItems()">
        <ng-container *ngIf="selectedItems.length > 1; then plural else singular"></ng-container>
        <ng-template #singular>{{'library.itemSelected'| amdocsTranslate:[selectedItems.length]}}</ng-template>
        <ng-template #plural>{{'library.itemsSelected'| amdocsTranslate:[selectedItems.length]}}</ng-template>
      </span>
            <button class="add-to-plan-button" (click)="addItemsToLearningPlan()">
        <div class="text">{{'library.addToPlan'| amdocsTranslate}}</div>
      </button>
        </div>
    </div>

    <lib-amdocs-app-loader [loaderClass]="'library-loader'" *ngIf="showLoader"></lib-amdocs-app-loader>
</div>

<ng-template #createNewItemButton>
    <button class="btn btn-small secondary icon-button" (click)="openItemEditForm()" [disabled]="isItemCreationProcess">
    <div class="text">
      <img class="button-icon" lib-amdocs-image-version src="/assets/icons/plus_icon.svg">
      <span>{{'library.create'| amdocsTranslate}}</span>
    </div>
  </button>
</ng-template>