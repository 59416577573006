export enum LearningTemplateStatus {
  Published = 'published',
  Draft = 'draft',
  PublishedDW = 'publishedDW'
}

export enum SortableTemplatesStatuses {
  All = 'All',
  CurrentUser = 'You',
  OtherUsers = 'Other'
}
