<div class="footer-wrapper">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="amdocs-logo">
          <img lib-amdocs-image-version src="/assets/icons/amdocs-logo.svg"/>
        </div>
        <div>
          <span class="rights-text">© {{copyYear}} Amdocs. All rights reserved</span>
        </div>
      </div>
      <div>
        <div class="rate">{{'footer.rate' | amdocsTranslate}}</div>
        <a class="white-link" [href]="CONSTANTS.LINKS.IMPROVE" target="_blank">{{'footer.improve' | amdocsTranslate}}</a>
      </div>
    </div>

  </div>
</div>
