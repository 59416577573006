import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core/api.service';


@Injectable()
export class PdfGeneratorService {

  constructor(private apiService: ApiService) {
  }

  generatePdf(base64String, title) {
    const byteArray = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));
    const file = new Blob([byteArray], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(file);
    let fileName = title;
    let link = document.createElement('a');
    link.download = fileName;
    link.target = '_blank';
    link.href = fileUrl;

    document.body.appendChild(link);
    link.click();
  }
}


