import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CONSTANTS } from './constants';

const routes: Routes = [
  {
    path: CONSTANTS.ROUTES.HOME,
    loadChildren: () => import('./feature/home/home.module').then(m => m.HomeModule)
  },
  {
    path: CONSTANTS.ROUTES.PLAN,
    loadChildren: () => import('./feature/create-learning-plan/create-learning-plan.module').then(m => m.CreateLearningPlanModule)
  },
  {
    path: CONSTANTS.ROUTES.LIBRARY,
    loadChildren: () => import('./feature/plan-library/plan-library.module').then(m => m.PlanLibraryModule)
  },
  {
    path: '**',
    redirectTo: CONSTANTS.ROUTES.HOME
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
