import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  AmdocsEventBusService,
  AmdocsTranslateService,
} from 'amdocs-core-package';
import { SearchTypes } from '../../../models/library';
import {
  LearningItem,
  LearningPlanPart,
  LearningPlanType,
  SearchLearningItems,
} from '../../../models/learningPlan';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { CONSTANTS } from '../../../constants';
import { LibraryService } from './library.service';
import { CreateLearningPlanService } from '../../../feature/create-learning-plan/create-learning-plan.service';
import { LibraryItemsSelectionsService } from '../../services/libraryItemsSelections.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedItemsPopupComponent } from '../selected-items-popup/selected-items-popup.component';
import { UtilsService } from 'src/app/core/utils.service';
import { of } from 'rxjs';

enum EnumSortBy {
  Relevant = 'Relevant',
  Lexicographic = 'Lexicographic',
  LatestEdited = 'LatestEdited',
}

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnChanges {
  constructor(
    private translate: AmdocsTranslateService,
    private eventBus: AmdocsEventBusService,
    private translateService: AmdocsTranslateService,
    private libraryService: LibraryService,
    private createLearningPlanService: CreateLearningPlanService,
    private libraryItemsSelectionsService: LibraryItemsSelectionsService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private createPlanService: CreateLearningPlanService // Part of duplication issue from initDropDownData in templates
  ) {
    this.libraryItemsSelectionsService.selectedLibraryItemsSelector.subscribe(
      (items) => {
        this.selectedItems = items;
      }
    );
  }

  @Input() showLibrary: boolean;
  @Input() partId: number;
  @Input() selectedPlan: LearningItem;
  @Input() parts: LearningPlanPart[];
  @Input() selectableItems = true;
  @Input() isEditLibraryMode = false;

  @Output() closeCallback: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addItemsToLearningPlanCallback: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() itemEditedCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemActivationChangedCallback: EventEmitter<any> =
    new EventEmitter<any>();

  public LearningPlanType = LearningPlanType;
  public partControl: UntypedFormControl;
  public partsList = [];
  public searchControl: UntypedFormControl;
  public selectedType = SearchTypes.Item;
  public sortControl: UntypedFormControl;
  public showInactiveCtrl: UntypedFormControl;
  public sortList = [
    {
      key: EnumSortBy.Relevant,
      value: this.translate.getText('library.sortBy.relevant'),
    },
    {
      key: EnumSortBy.LatestEdited,
      value: this.translate.getText('library.sortBy.recently'),
    },
    {
      key: EnumSortBy.Lexicographic,
      value: this.translate.getText('library.sortBy.lexicog'),
    },
  ];
  public libraryItems: any = [];
  public pageSize = CONSTANTS.LIBRARY.PAGE_SIZE;
  public totalRows = 20;
  public pageNumber = 1;
  public foundItemsCount = 0;
  public showCreateNewLearningItemPopup: boolean;
  public selectedItems: Array<LearningItem>;
  public isItemCreationProcess = false;
  public optimisticRecords: LearningItem[] = [];
  public hideItems = false;
  public createdOrUpdatedByYou = true;
  public sortBy: EnumSortBy = EnumSortBy.Relevant;
  public bsModalRef?: BsModalRef;
  public learningTypes = [];
  public data: any; // Part of duplication issue from initDropDownData in templates
  public editSelectedPlan: boolean;
  public showLoader: boolean = false;

  @ViewChild('libraryItemsWrapper') private libraryItemsWrapper: ElementRef;

  // ToDo: Change this legacy with proper component life Cycle
  init(): void {
    this.showInactiveCtrl = new UntypedFormControl();
    if (!this.isEditLibraryMode) {
      this.libraryItems = [];
      this.partControl = new UntypedFormControl();
      this.sortControl = new UntypedFormControl(EnumSortBy.Relevant);
      this.searchControl = new UntypedFormControl();
      this.searchControl.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((searchText) => {
          this.pageNumber=1;
          this.search(searchText);
        });
      this.showInactiveCtrl.valueChanges.subscribe(() => {
        this.pageNumber = 1;
        this.search();
      });
      this.sortControl.valueChanges.subscribe((searchOrder) => {
        this.sortBy = searchOrder;
        this.pageNumber = 1;
        this.search();
      });
      this.search();
    }

    this.libraryService.initCreateNewItemData().subscribe(() => {
      this.learningTypes = this.utils.dropdownData.learningTypes;
    });
  }

  pageChanged(pageNumber): void {
    this.pageNumber = pageNumber;
    this.search();
  }

  search(queryPhrase?): void {
    const newQueryPhrase = this.createdOrUpdatedByYou
      ? ''
      : queryPhrase || this.searchControl.value || '';

    if (newQueryPhrase == '' && !this.createdOrUpdatedByYou) {
      this.foundItemsCount = 0;
      this.pageNumber = 1;

      this.libraryItems = [];
      return;
    }
    const params = {
      input: {
        ...{ activated: this.showInactiveCtrl.value ? false : true },
        queryPhrase: newQueryPhrase,
        createdOrUpdatedByYou: this.createdOrUpdatedByYou,
        sortBy: this.createdOrUpdatedByYou
          ? EnumSortBy.LatestEdited
          : this.sortBy,
        size: this.pageSize,
        start: this.pageSize * (this.pageNumber - 1),
      },
    };
    this.searchLearningItems(params);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.showLibrary &&
      !changes.showLibrary.previousValue &&
      changes.showLibrary.currentValue
    ) {
      this.init();
    }

    if (changes.selectedPlan && changes.selectedPlan.currentValue) {
      this.createdOrUpdatedByYou = false;
      this.showInactiveCtrl.setValue(!this.selectedPlan.activated);
      this.search(this.selectedPlan.code);
      this.editSelectedPlan = true;
    }

    if (this.partId) {
      this.partControl.setValue(this.partId);
    }

    if (this.parts) {
      this.partsList = [];
      this.parts.forEach((part) =>
        this.partsList.push({ key: part.order, value: part.name })
      );
    }
  }

  closeLibrary(refresh: boolean = false): void {
    this.libraryItemsSelectionsService.clearSelections();
    this.showLibrary = false;
    this.editSelectedPlan = false;
    this.isItemCreationProcess = false;
    this.selectedPlan = null;
    this.closeCallback.emit(refresh);
  }

  addItemsToLearningPlan(): void {
    const params = {
      partId: this.partControl.value,
      items: this.selectedItems,
    };
    this.addItemsToLearningPlanCallback.emit(params);
    this.libraryItemsSelectionsService.clearSelections();
    this.showLibrary = false;
  }

  itemCheckChanged($event): void {
    if ($event.value) {
      this.libraryItemsSelectionsService.addSelection($event.item);
    } else {
      this.libraryItemsSelectionsService.removeSelection($event.item.code);
    }
  }

  isItemChecked(itemCode: LearningItem['code']): boolean {
    return !!this.selectedItems.find((item) => item.code === itemCode);
  }

  public clearSearchInput() {
    if (!this.searchControl.disabled) {
      this.searchControl.setValue('');
    }
  }

  searchLearningItems(params): void {
    this.showLoader = true;
    this.libraryService
      .searchLearningItems(params)
      .pipe(
      finalize(() => {
        this.showLoader = false;
      }),
      catchError((error) => {
        console.error('Error occurred:', error);
        this.handleError(error);  // Custom error handling logic
        return of({ items: [], found: 0 });  // Return an empty response to continue the observable chain
      })
    )
      
      .subscribe((res: SearchLearningItems) => {
        this.libraryItems = res.items;
        this.foundItemsCount = res.found;
        if(this.showInactiveCtrl.value){
          this.libraryItems=this.filterByActivated(this.libraryItems)
        }
        const fulfilledCodes = this.checkOptimmisticProcess();
        if (fulfilledCodes.length) {
          this.removeOptimisticRecords(fulfilledCodes);
        }
      });
  }
  handleError(error): void {
  // Implement your error handling logic here
  // For example, show an error message to the user
  alert('An error occurred while fetching learning items. Please try again later.');
}
   filterByActivated(array) {
    return array.filter(item => item.activated === false);
  }

  // ToDo: This method has to be removed from here.
  openItemEditForm(): void {
    this.setCreatedOrUpdatedByYou(true);
    this.libraryItemsWrapper.nativeElement.scrollTop = 0;
    this.isItemCreationProcess = true;
    this.showInactiveCtrl.disable();
    this.searchControl.disable();
    this.sortControl.disable();
  }

  public createItem($event): void {
    const item = $event;
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.createLearningPlanService
      .createLearningItem(item)
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      )
      .subscribe(
        (res: any) => {
          if (res?.createLearningItem?.code) {
            item.input.code = res?.createLearningItem?.code;
            item.input.activated = true;
            this.finishItemCreationProcess(item.input);
            this.addOptimisticRecord(item.input);
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            this.translateService.getText(
              'createPlan.createLearningItemFailed'
            ),
          ]);
        }
      );
  }

  public updateItem(e): void {
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.createLearningPlanService
      .upsertLearningItem(e)
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      )
      .subscribe(
        () => {
          if (this.isEditLibraryMode) {
            this.closeLibrary();
          }
          this.itemEditedCallback.emit(e.input);
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            this.translateService.getText(
              'createPlan.updateLearningItemFailed'
            ),
          ]);
        }
      );
  }

  public editItemCallback(e) {
    if (this.isEditLibraryMode) {
      this.closeLibrary();
    }
  }

  public updateItemActiveState(e): void {
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.createLearningPlanService
      .updateLearningItemActivation(e)
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      )
      .subscribe(
        () => {
          this.itemActivationChangedCallback.emit(e.input);
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            this.translateService.getText(
              'createPlan.updateLearningItemFailed'
            ),
          ]);
        }
      );
  }

  finishItemCreationProcess(item?: LearningItem): void {
    this.isItemCreationProcess = false;
    if (item) {
      if (this.selectableItems) {
        this.libraryItemsSelectionsService.addSelection(item);
      }
      item.recent = true;
    }
    this.showInactiveCtrl.enable();
    this.searchControl.enable();
    this.sortControl.enable();
  }

  checkOptimmisticProcess(): string[] {
    const fulfilledCodes = [];
    this.optimisticRecords.forEach((optimisticRecord) => {
      if (
        this.libraryItems.find((item) => item.code === optimisticRecord.code)
      ) {
        fulfilledCodes.push(optimisticRecord.code);
      }
    });
    return fulfilledCodes;
  }

  addOptimisticRecord(item: LearningItem): void {
    this.optimisticRecords.unshift(item);
    // Update storage
    localStorage.setItem(
      'optimisticRecords',
      JSON.stringify(this.optimisticRecords)
    );
  }

  removeOptimisticRecords(itemsCodes: string[]): void {
    this.optimisticRecords = this.optimisticRecords.filter(
      (record) => record.code in itemsCodes
    );
    // Update storage
    localStorage.setItem(
      'optimisticRecords',
      JSON.stringify(this.optimisticRecords)
    );
  }

  public applyItemsVisibility($event): void {
    this.hideItems = $event;
  }

  public setCreatedOrUpdatedByYou(limited: boolean): void {
    this.pageNumber = 1;
    this.foundItemsCount = 0;
    if (!limited && this.isItemCreationProcess) {
      this.finishItemCreationProcess();
    }
    if (this.createdOrUpdatedByYou == limited) {
      return;
    }
    this.createdOrUpdatedByYou = limited;
    this.search();
  }

  openModalSelectedItems() {
    this.bsModalRef = this.modalService.show(SelectedItemsPopupComponent, {
      initialState: {},
    });
    this.bsModalRef.content.title = this.translateService.getText(
      'selectedItemsPopup.title'
    );
    this.bsModalRef.content.closeBtnName = this.translateService.getText(
      'selectedItemsPopup.closeBtnName'
    );
  }
}
