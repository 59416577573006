// @ts-ignore
export const CONSTANTS = {
  Toaster: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  ROUTES: {
    ROOT: '',
    HOME: 'home',
    PLAN: 'learning-plan',
    LIBRARY: 'plan-library'
  },
  EVENTS: {
    TOGGLE_FULL_PAGE_LOADER: 'toggle-full-page-loader',
    NOTIFY_USER_ALERT: 'notify-user-alert',
    SHOW_TOASTER: 'show-toaster',
    SET_PAGE_TILE: 'set-page-title'
  },
  LIBRARY: {
    MIN_CHARS_FOR_SEARCH: 2,
    PAGE_SIZE: 10
  },
  REGEX: {
    TWO_DECIMAL_NUMBER: /^[0-9]*(\.[0-9]{0,2})?$/
  },
  LINKS: {
    IMPROVE: 'https://forms.office.com/Pages/ResponsePage.aspx?id=yqPsyHYS1UadnaDyoCiSD4qMIX9Cj9hDoasUc_U7vC1UNUNURUk4UEFGNFcxNUFKWVBDSEhMUFk4TS4u'
  },
  DURATION_TYPE: {
    MIN: 1,
    HOURS: 2
  }
};

