import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  AmdocsEventBusService,
  AmdocsTranslateService,
  ICodeAndName,
} from 'amdocs-core-package';
import { filter, take } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/constants';
import { CreateLearningPlanService } from 'src/app/feature/create-learning-plan/create-learning-plan.service';
import {
  LearningPlanForm,
  LearningPlanType,
  ProductPractice,
} from 'src/app/models/learningPlan';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductPracticePopupComponent } from '../product-practice-popup/product-practice-popup.component';
import { UtilsService } from 'src/app/core/utils.service';

@Component({
  selector: 'app-learning-plan-form',
  templateUrl: './learning-plan-form.component.html',
  styleUrls: ['./learning-plan-form.component.scss'],
})
export class LearningPlanFormComponent implements OnInit, OnChanges {
  constructor(
    private createPlanService: CreateLearningPlanService,
    private eventBus: AmdocsEventBusService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private translateService: AmdocsTranslateService,
    private utilsService: UtilsService
  ) {}

  @Input() org2List: any[];
  @Input() managedProducts: ProductPractice[] = [];
  @Input() customProducts: ProductPractice[] = [];
  @Input() rolesList: any[];
  @Input() locationsList: any[];
  @Input() accountsList: any[];
  @Input() seniorityList: any[];
  @Input() planDetails: any;
  @Input() showSubmit: boolean = false;
  @Input() isCopy: boolean = false;
  @Input() isEdit: boolean = false;
  @Output() isFormValid: EventEmitter<any> = new EventEmitter();
  @Output() submitPlan: EventEmitter<any> = new EventEmitter();

  public LearningPlanType = LearningPlanType;
  public form = this.fb.group({
    title: new UntypedFormControl('', [Validators.required]),
    expectedTime: new UntypedFormControl('', [
      this.greaterThanZeroValidator,
      Validators.pattern(CONSTANTS.REGEX.TWO_DECIMAL_NUMBER),
    ]),
    empSeniorityID: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    prerequisites: new UntypedFormControl(''),
    org2ID: new UntypedFormControl(),
    org3ID: new UntypedFormControl(),
    org4ID: new UntypedFormControl(),
    org5ID: new UntypedFormControl(),
    roleIDs: new UntypedFormControl([]),
    productIDs: new UntypedFormControl([]),
    accountIDs: new UntypedFormControl([]),
    locationIDs: new UntypedFormControl([]),
  });
  public selectedPlan: LearningPlanForm;
  public org3List = [];
  public org4List = [];
  public org5List = [];
  public partId: number;
  public planId: string;
  private editFormLatestValue: any;
  private formLoaded = new BehaviorSubject(false);
  public bsModalRef?: BsModalRef;

  // Product/practice
  public selectedManagedProducts: string[] = [];
  public selectedCustomProducts: string[] = [];

  ngOnInit(): void {
    this.form.controls.title.valueChanges.subscribe((value) => {
      if (
        value.charCodeAt(0) === 32 ||
        value.charCodeAt(value.length - 1) === 32
      ) {
        this.form.controls.title.setValue(value.trim());
      }
    });
  }

  get utils(): UtilsService {
    return this.utilsService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.planDetails && changes.planDetails.currentValue && this.form) {
      const transformedForm: any = this.clearEmptyOrgUnits(
        changes.planDetails.currentValue
      );
      this.form.patchValue(transformedForm, { emitEvent: false });

      if (this.isEdit) {
        // Set Initial value for selectedManagedProducts and selectedCustomProducts
        if (changes.planDetails.firstChange) {
          this.setProductPracticeInitialValue(changes.planDetails.currentValue);
        }
        this.formLoaded
          .pipe(
            filter((value) => !!value),
            take(1)
          )
          .subscribe(
            () => {
              this.editFormLatestValue = this.form.value;
              this.form.valueChanges.subscribe((newForm) => {
                if (!newForm.productIDs.length) {
                  this.selectedManagedProducts = [];
                  this.selectedCustomProducts = [];
                }
                const isFormDiff = isEqual(this.editFormLatestValue, newForm);
                if (!isFormDiff) {
                  let transformedForm: any = this.transformFormBeforeSubmit(
                    this.form.value
                  );
                  transformedForm.productIDs = this.selectedManagedProducts;
                  transformedForm.customProductIDs =
                    this.selectedCustomProducts;

                  if (this.validateForm()) {
                    this.isFormValid.emit(false);
                    return;
                  }
                  this.isFormValid.emit(true);
                  this.editFormLatestValue = this.form.value;
                  this.submitPlan.emit(transformedForm);
                }
              });
            },
            (error) => {
              this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
                CONSTANTS.Toaster.ERROR,
                error.message,
              ]);
            }
          );
      }

      if (this.isCopy) {
        this.setProductPracticeInitialValue(changes.planDetails.currentValue);
        this.form.controls.title.setValue('');
      }
      if (transformedForm.org2ID) {
        this.onChangeOrg2(
          { code: transformedForm.org2ID, name: '' },
          { code: transformedForm.org3ID, name: '' }
        );
      } else {
        this.formLoaded.next(true);
      }

      if (transformedForm.org3ID) {
        this.onChangeOrg3(
          { code: transformedForm.org3ID, name: '' },
          transformedForm.org4ID
            ? { code: transformedForm.org4ID, name: '' }
            : null,
          transformedForm.org5ID
            ? { code: transformedForm.org5ID, name: '' }
            : null
        );
      }
    }
  }

  private greaterThanZeroValidator(control: UntypedFormControl) {
    const value = control.value;
    if (value?.length && Number(value) === 0) {
      return { zeroError: true };
    } else if (value?.length && Number(value) < 0) {
      return { greaterThanZero: true };
    }

    return null;
  }

  onChangeOrg2(org2: ICodeAndName, org3: ICodeAndName = null): void {
    if (org2) {
      this.form.controls.org2ID.setValue(org2.code, { emitEvent: false });
      const selectedOrg2Item = this.org2List.find(
        (org2Item) => org2Item.code === org2.code
      );
      if (
        selectedOrg2Item &&
        selectedOrg2Item.subUnits &&
        selectedOrg2Item.subUnits.length
      ) {
        this.org3List = selectedOrg2Item.subUnits.map((o) => ({
          code: o.code,
          name: o.name,
        }));
      }
      this.clearOrgValuesFromIndex(3);
      if (!org3?.code) {
        this.formLoaded.next(true);
      }
    } else {
      this.clearOrgValuesFromIndex(2);
      this.formLoaded.next(true);
    }
  }

  onChangeOrg3(
    org3: ICodeAndName,
    selectedOrg4Value = null,
    selectedOrg5Value = null
  ): void {
    if (org3 && org3.code) {
      this.form.controls.org3ID.setValue(org3.code, { emitEvent: false });
      this.createPlanService.getOrg3Units(org3.code).subscribe(
        (org3Units) => {
          if (
            org3Units.length &&
            org3Units[0].subUnits &&
            org3Units[0].subUnits.length
          ) {
            this.org4List = org3Units[0].subUnits.map((o) => ({
              code: o.code,
              name: o.name,
            }));
            if (this.org4List.length && selectedOrg4Value) {
              this.onChangeOrg4(selectedOrg4Value, selectedOrg5Value);
            } else {
              this.formLoaded.next(true);
            }
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
      this.clearOrgValuesFromIndex(4);
    } else {
      this.clearOrgValuesFromIndex(3);
      this.formLoaded.next(true);
    }
  }

  onChangeOrg4(org4: ICodeAndName, selectedOrg5Value = null): void {
    if (org4 && org4.code) {
      this.form.controls.org4ID.setValue(org4.code, { emitEvent: false });
      this.createPlanService.getOrg4Units(org4.code).subscribe(
        (org4Units) => {
          if (
            org4Units.length &&
            org4Units[0].subUnits &&
            org4Units[0].subUnits.length
          ) {
            this.org5List = org4Units[0].subUnits.map((o) => ({
              code: o.code,
              name: o.name,
            }));
            if (this.org5List.length && selectedOrg5Value) {
              this.onChangeOrg5(selectedOrg5Value);
            } else {
              this.formLoaded.next(true);
            }
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
      this.clearOrgValuesFromIndex(5);
    } else {
      this.clearOrgValuesFromIndex(4);
      this.formLoaded.next(true);
    }
  }

  onChangeOrg5(org5: ICodeAndName): void {
    if (org5 && org5.code) {
      this.form.controls.org5ID.setValue(org5.code);
      this.formLoaded.next(true);
    } else {
      this.clearOrgValuesFromIndex(5);
      this.formLoaded.next(true);
    }
  }

  setProductPracticeInitialValue(currentValue) {
    this.selectedManagedProducts = currentValue.productIDs || [];
    this.selectedCustomProducts = currentValue.customProductIDs || [];
    this.form.controls.productIDs.patchValue(
      [...this.selectedManagedProducts, ...this.selectedCustomProducts],
      { emitEvent: false }
    );
  }

  onChangeProduct(products: string[]): void {
    this.form.controls.productIDs.setValue(products.length ? products : []);
  }

  onChangeRole(roles: string[]): void {
    this.form.controls.roleIDs.setValue(roles.length ? roles : []);
  }

  onChangeAccount(accounts: string[]): void {
    this.form.controls.accountIDs.setValue(accounts.length ? accounts : []);
  }

  onChangeLocation(locations): void {
    this.form.controls.locationIDs.setValue(locations.length ? locations : []);
  }

  clearOrgValuesFromIndex(fromIndex: number): void {
    switch (fromIndex) {
      case 2:
        this.form.controls.org2ID.setValue(null);
        this.org3List = [];
      case 3:
        this.form.controls.org3ID.setValue(null);
        this.org4List = [];
      case 4:
        this.form.controls.org4ID.setValue(null);
        this.org5List = [];
      case 5:
        this.form.controls.org5ID.setValue(null);
    }
  }

  private validateForm() {
    this.form.controls.title.markAsDirty();
    this.form.controls.expectedTime.markAsDirty();
    return this.form.invalid;
  }

  planSubmit(): void {
    if (this.validateForm()) {
      return;
    }
    let dataFormSubmit: any = this.transformFormBeforeSubmit(this.form.value);
    dataFormSubmit.productIDs = this.selectedManagedProducts;
    dataFormSubmit.customProductIDs = this.selectedCustomProducts;
    this.submitPlan.emit(dataFormSubmit);
  }

  transformFormBeforeSubmit(objectForTransform) {
    return Object.entries(objectForTransform).reduce((acc, [key, value]) => {
      acc[key] = value === null ? '' : value;
      return acc;
    }, {});
  }

  clearEmptyOrgUnits(objectForTransform) {
    return Object.entries(objectForTransform).reduce((acc, [key, value]) => {
      acc[key] = value === '' ? null : value;
      return acc;
    }, {});
  }

  updateCustomProducts = (newList: ProductPractice[]): void => {
    this.customProducts = newList;
  };

  updateProductsSelection = (
    selectedManaged: string[],
    selectedCustom: string[]
  ): void => {
    this.selectedManagedProducts = selectedManaged;
    this.selectedCustomProducts = selectedCustom;

    this.form.controls.productIDs.setValue([
      ...this.selectedManagedProducts,
      ...this.selectedCustomProducts,
    ]);
  };

  openModalProductPractice() {
    this.bsModalRef = this.modalService.show(ProductPracticePopupComponent, {
      initialState: {
        managed: this.managedProducts,
        custom: this.customProducts,
        selectedManaged: this.selectedManagedProducts,
        selectedCustom: this.selectedCustomProducts,
        updateCustomProducts: this.updateCustomProducts,
        updateProductsSelection: this.updateProductsSelection,
      },
      class: 'popup-960',
    });
    this.bsModalRef.content.title = this.translateService.getText(
      'productPracticePopup.title'
    );
    this.bsModalRef.content.closeBtnName = this.translateService.getText(
      'productPracticePopup.closeBtnName'
    );
    this.bsModalRef.content.cancelBtnText = this.translateService.getText(
      'productPracticePopup.cancelBtnText'
    );
  }

  get productsList(): ProductPractice[] {
    return [...this.managedProducts, ...this.customProducts];
  }
}
