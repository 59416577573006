import { ICodeAndName } from 'amdocs-core-package';

export enum LearningPlanType {
  Create = 1,
  Library = 2,
  CreateLearningItem = 3,
  LibrarySync = 4,
  EditLearningItem = 5,
  SelectionListItem = 6,
}

export interface CreateTemplateLearningPlan {
  createTemplateLearningPlan: {
    planID: string;
  };
}

export interface SearchLearningItems {
  items: LearningItem[];
  found: number;
  searchMetadata?: string;
}

export interface SearchCourses {
  searchCourses: LearningItem[];
}

export interface OrgUnit {
  code: string;
  name: string;
  parentUnitCode?: string;
  subUnits?: ICodeAndName[];
}

export interface LearningItem {
  activated: boolean;
  title: string;
  typeID: string;
  typeName?: string;
  domainID: string;
  description: string;
  resourceURL: string;
  duration: number;
  moreDetailsFlag?: boolean;
  selected?: boolean;
  course?: string;
  role?: string;
  productIDs?: string[];
  customProductIDs?: string[];
  domain?: string;
  accountIDs?: string;
  code?: string;
  location?: string;
  technology?: string;
  recent?: boolean;
  updatedBy?: string;
  updatedAt?: string;
  updatedByUser?: string;
  createdAt?: string;
  createdByUser?: string;
}

export interface UpsertLearningItemInput {
  title: string;
  typeID: string;
  typeName?: string;
  domainID: string;
  description: string;
  resourceURL: string;
  duration: number;
  location?: string;
  roleIDs: string[];
  productIDs: string[];
  customProductIDs?: string[];
  accountIDs: string;
  expectedTime: number;
  creationDate: string;
  technology: string;
}

export interface InsertLearningItemInput extends UpsertLearningItemInput {
  code: string;
}

export interface LearningPlanPart {
  name: string;
  order?: number;
  items: LearningItem[];
}

export enum CreateNewItemWizard {
  WebsiteLink = 1,
  ItemDetailsUrlExists = 2,
  ItemDetailsSkip = 3,
}

export interface LearningPlanForm {
  title: string;
  empSeniorityID?: string;
  location?: string;
  roleIDs?: string[];
  productIDs?: string[];
  accountIDs?: string[];
  org2ID: string;
  org3ID: string;
  org4ID: string;
  org5ID: string;
  expectedTime?: string;
  autoSaveAt?: number | string;
  editedAt?: number | string;
  status: string;
  prerequisites?: any;
  description?: string;
  code?: string;
}

export interface ProductPractice {
  code: string;
  name: string;
  selected?: boolean;
}
