<div class="modal-header">
  <span class="label-h1">{{ title }}</span>
  <div class="close-popup-button" role="button" (click)="bsModalRef.hide()" >
    <img src="/assets/icons/popup-close.svg" />
  </div>
</div>
<div class="modal-body">

  <div class="search-controls-wrapper">
    <div class="show-selected-wrapper">
      <lib-amdocs-checkbox [elementId]="'selected-olny-checkbox'" [control]="selectedOnlyCheckboxCtrl">{{'productPracticePopup.showAllSelectedOnly' | amdocsTranslate}}</lib-amdocs-checkbox>
    </div>
    <div class="search-wrapper">
      <div class="search-input-wrapper" [ngClass]="{'disabled': showActiveOnly}">
        <lib-amdocs-input-field
          [elementId]="'library-search'"
          [placeHolder]="'productPracticePopup.search' | amdocsTranslate"
          [control]="searchControl"
          [hideLabel]="true"
        ></lib-amdocs-input-field>
        <img class="find-an-item-icon" lib-amdocs-image-version src="/assets/icons/find-an-item.svg">
        <img class="clear-input-icon" *ngIf="searchControl.value" (click)="clearSearchInput()" lib-amdocs-image-version src="/assets/icons/clear-input.svg">
      </div>
    </div>
  </div>
  <div class="lists-wrapper">
    <div class="official-names-wrapper">
      <div class="list-header-wrapper">
        <div class="title">
          <h3>Official Names</h3>
        </div>
        <div class="add-an-option-button">
      
      
        </div>
      </div>
      <div class="checkbox-list-wrapper">
        <div class="product-item" *ngFor="let officialName of getSelectedList(officialNamesList, showActiveOnly) | amdocsSearch: searchString : 'name'">
          <app-product-practice-checkbox
            [item]="officialName"
            (checkCallback)="onOfficialNameCheckChanged($event)"
          ><span [innerHTML]="officialName.name | amdocsHighlightText: searchString"></span></app-product-practice-checkbox>
        </div>
      </div>
    </div>
    <div class="lingo-wrapper">
      <div class="list-header-wrapper">
        <div class="title">
          <h3>Amdocs Lingo</h3>
        </div>
        <div class="add-an-option-button">
          <button class="btn secondary btn-small icon-button toggle-add-new-item-btn" [ngClass]="{'active': showAddnewLingoForm}" (click)="toggleAddNewLingo()">
            <div class="text">
              <img *ngIf="!showAddnewLingoForm" lib-amdocs-image-version class="button-icon icon-before" src="/assets/icons/plus_icon.svg"/>
              <img *ngIf="showAddnewLingoForm" lib-amdocs-image-version class="button-icon icon-before" src="/assets/icons/cross.svg"/>
              <span class="btn-text-next">{{'productPracticePopup.addAnOption' | amdocsTranslate}}</span>
            </div>
          </button>
        </div>
      </div>
      <div class="add-an-option-form-wrapper" *ngIf="showAddnewLingoForm">
        <lib-amdocs-input-field
          [elementId]="'new-lingo-name'"
          [placeHolder]="'productPracticePopup.addAnOption' | amdocsTranslate"
          [control]="newLingoNameControl"
          [errorsMessages]="lingoNameErrors"
          [hideLabel]="true"
          class="text-input"
        ></lib-amdocs-input-field>
        <button class="btn secondary btn-small icon-button add-item-button" (click)="addNewLingo()">
          <div class="text">
            <img lib-amdocs-image-version class="" src="/assets/icons/plus_icon.svg"/>
          </div>
        </button>
      </div>
      <div class="checkbox-list-wrapper" [ngClass]="{'with-form': showAddnewLingoForm}" #lingoScrollableList>
        <div class="product-item" *ngFor="let lingo of getSelectedList(lingoList, showActiveOnly) | amdocsSearch: searchString : 'name'">
          <app-product-practice-checkbox
            [item]="lingo"
            (checkCallback)="onLingoCheckChanged($event)"
          ><span [innerHTML]="lingo.name | amdocsHighlightText: searchString"></span></app-product-practice-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>