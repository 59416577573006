import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() pageSize = 12;
  @Input() pageNumber = 1;
  @Input() totalRows = 0;

  @Output() onPageChange: EventEmitter<any> = new EventEmitter();

  private origValue: number;

  constructor() {
  }

  ngOnInit() {
    this.origValue = this.pageNumber;
  }

  get allowNext(): boolean {
    return this.pageNumber < this.lastPage;
  }

  get allowPrev(): boolean {
    return this.pageNumber > 1;
  }

  get lastPage(): number {
    return Math.ceil(this.totalRows / this.pageSize);
  }

  get pages(): any {
    const pages = [];
    const numOfPages = Math.ceil(this.totalRows / this.pageSize);
    for (let i = 1; i <= numOfPages; i++) {
      const item = { key: i, value: i };
      pages.push(item);
    }
    return pages;
  }

  next(): void {
    if (this.allowNext) {
      this.pageNumber++;
      this.pageChange();
    }
  }

  prev(): void {
    if (this.allowPrev) {
      this.pageNumber--;
      this.pageChange();
    }
  }

  selectPage(pageNumberSelected): void {
    this.pageNumber = pageNumberSelected;
    this.pageChange();
  }

  trackByMethod(index:number, el:any): number {
    return el.key;
  }

  pageChange(): void {
    this.onPageChange.emit(this.pageNumber);
  }
}

