import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmdocsUtilsModule } from 'amdocs-core-package';
import { PwaService } from './pwa.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, AmdocsUtilsModule],
  providers: [PwaService, UserService, UtilsService],
})
export class CoreModule {}
