<lib-amdocs-popup *ngIf="showOpenPlanPopup" [showCloseButton]="true" #popup (closeCallback)="close()"
                  [customClass]="'open-plan'">
  <div class="mh-popup-header">
    <span class="label-h1">{{ 'openPlan.title' | amdocsTranslate }}</span></div>
  <div class="open-plan-content">
    <div class="top-wrapper">
      <div class="search-input-wrapper">
        <lib-amdocs-input-field
          [elementId]="'openPlan-search'"
          [placeHolder]="'openPlan.findAnItem' | amdocsTranslate"
          [control]="searchControl"
          [label]="''"
        ></lib-amdocs-input-field>
        <img class="find-an-item-icon" lib-amdocs-image-version src="/assets/icons/find-an-item.svg">
      </div>
    </div>
    <div class="plans-content-wrapper">
      <ng-container *ngFor="let item of items">
        <app-plan-item [item]="item"></app-plan-item>
      </ng-container>
    </div>
  </div>
</lib-amdocs-popup>

