import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ProductPractice } from '../../../models/learningPlan'
import { UntypedFormControl } from '@angular/forms'

@Component({
  selector: 'app-product-practice-checkbox',
  templateUrl: './product-practice-checkbox-item.component.html',
  styleUrls: ['./product-practice-checkbox-item.component.scss'],
})
export class ProductPracticeCheckboxComponent implements OnInit, OnChanges {

  @Input() item: ProductPractice;
  @Input() searchString: String
  @Output() checkCallback: EventEmitter<any> = new EventEmitter<any>();


  public checkboxCtrl: UntypedFormControl;

  constructor() {
  }
  
  ngOnInit(): void {
    this.initCheckbox()
  }

  initCheckbox(): void {
    this.checkboxCtrl = new UntypedFormControl()

    this.checkboxCtrl.setValue(this.item.selected)

    this.checkboxCtrl.valueChanges.subscribe((value) => {
      if (this.checkCallback.observers.length) {
        this.item.selected = value
        this.checkCallback.emit(this.item);
      }
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
  //   if (this.showAddItem && this.checkboxCtrl && changes.isChecked !== undefined) {
  //     this.checkboxCtrl.setValue(this.isChecked)
  //   }
  }


  // private itemActiveSwitch(itemCode: string, activated: boolean): void {
  //   this.item.activated = activated
  //   const payload = {
  //     input: {
  //       itemCode,
  //       activated,
  //     }
  //   }
  //   if (this.updateItemActivieStateAction.observers.length) {
  //     this.updateItemActivieStateAction.emit(payload);
  //   }
  // }
}
