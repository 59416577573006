import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '../../../core/utils.service';
import { CONSTANTS } from 'src/app/constants';
import { Subject } from 'rxjs';
import {
  AmdocsEventBusService,
  AmdocsPopupComponent,
  AmdocsTranslateService,
} from 'amdocs-core-package';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  LearningPlanForm,
  LearningPlanType,
} from 'src/app/models/learningPlan';
import { PreviewPlanService } from '../../services/preview-plan.service';
import { takeUntil, tap } from 'rxjs/operators';
import { LearningTemplateStatus } from 'src/app/models/learningTemplate';
import { Router } from '@angular/router';
import { ActionPopupComponent } from '../action-popup/action-popup.component';
import { LinkPlanPopupComponent } from '../link-plan-popup/link-plan-popup.component';
import { PdfGeneratorService } from '../../services/pdf-generator.service';
import { CreateLearningPlanService } from 'src/app/feature/create-learning-plan/create-learning-plan.service';
import { ApiService } from 'src/app/core/api.service';

@Component({
  selector: 'preview-plan-popup',
  templateUrl: './preview-plan-popup.component.html',
  styleUrls: ['./preview-plan-popup.component.scss'],
})
export class PreviewPlanComponent implements OnInit {
  public planInfo: any;
  public planCode: string;
  public planLink: string;
  public planId: string;
  public isLoading = true;
  public planDetails: LearningPlanForm;
  public planMetadata: any;
  public latestMetaData: any;
  public LearningPlanType = LearningPlanType;
  public isLearningPlanUpdated: boolean = false;
  public initialParts = [
    {
      name: 'Part 1',
      order: 1,
      items: [],
    },
    {
      name: 'Part 2',
      order: 2,
      items: [],
    },
    {
      name: 'Part 3',
      order: 3,
      items: [],
    },
  ];
  public parts = [];
  public LearningTemplateStatus = LearningTemplateStatus;
  public isFormInvalid: boolean = false;
  public isPublishing: boolean = false;
  public isSwichingToDraft: boolean = false;
  public learningTypesList = [];
  public duplicationModalRef?: BsModalRef;
  public emptyModalRef?: BsModalRef;
  public bsModalRefLocked?: BsModalRef;
  public bsPublishModalRef?: BsModalRef;
  public bsLinkModalRef?: BsModalRef;
  public showDeleteModal = false;
  public showLoader = false;
  public actionValue: string;
  public removeTemplateCallback: Function;
  public dropDownsData: any;
  public rolesList = [];

  public get showPlanInfo() {
    const planInfo = this.planInfo;
    if (!planInfo) {
      return false;
    }

    return (
      planInfo.description ||
      planInfo.expectedTime ||
      planInfo.seniority ||
      planInfo.role ||
      planInfo.locationLabel ||
      planInfo.account ||
      planInfo.org2 ||
      planInfo.org3 ||
      planInfo.org4 ||
      planInfo.org5 ||
      planInfo.customProductIDs?.length ||
      planInfo.productIDs?.length
    );
  }

  public autosaveTimer: ReturnType<typeof setTimeout> = null;
  private unsubscribe$ = new Subject();
  @ViewChild('deletePlanModal') deletePlanModal: AmdocsPopupComponent;

  constructor(
    public bsPreviewModalRef: BsModalRef,
    private utilsService: UtilsService,
    private eventBus: AmdocsEventBusService,
    private previewPlanService: PreviewPlanService,
    private router: Router,
    private modalService: BsModalService,
    private translateService: AmdocsTranslateService,
    private pdfGeneratorService: PdfGeneratorService,
    private createPlanService: CreateLearningPlanService,
    private apiService: ApiService    

  ) {}

  ngOnInit(): void {
    if (
      this.utilsService.dropdownData.roles.length === 0 &&
      this.utilsService.dropdownData.domains.length === 0
    ) {
      this.initDropDownData();
    } else {
      this.getLearningTemplateByCode();
    }
  }

  initDropDownData(): void {
    this.showLoader = true;
    this.createPlanService.initDropDowns().subscribe(
      {
        next: (res) => {
          this.dropDownsData = res;
          this.dropDownsData.roles.forEach((r) => {
            const roleItem = { code: r.role, name: r.name };
            this.rolesList.push(roleItem);
          });
      
          this.utils.dropdownData = { ...this.utils.dropdownData, ...this.dropDownsData };
          this.utils.dropdownData.roles = this.rolesList;
          this.showLoader = false;
        },
        complete: () => {
          this.getLearningTemplateByCode();
        }
      }
    )
  }

  getLearningTemplateByCode(): void {
    this.showLoader = true;
    this.previewPlanService
      .getLearningTemplate(this.planCode, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.showLoader = false;
          this.planDetails =
            res.draft?.templatePlanDetails ?? res.latest.templatePlanDetails;
          this.planInfo = this.utils.getValuesOfItem(this.planDetails);
          this.getOrgsData(this.planInfo);
          this.learningTypesList = Object.assign(
            {},
            ...this.utils.dropdownData.learningTypes.map((l) => ({
              [l.code]: l.name,
            }))
          );
          this.parts =
            res.draft?.templatePlanContent ?? res.latest.templatePlanContent;
          if (!this.parts.length) {
            this.parts = [...this.initialParts];
          }
          this.planMetadata = res.latest.templatePlanMetadata;
          this.planId = res.code;
          this.isLoading = false;
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
  }

  getOrgsData(item) {
    if (this.utils.dropdownData.org2Units && item.org2ID) {
      const selectedOrg2Item = this.utils.dropdownData.org2Units.find(
        (org2Item) => org2Item.code === item.org2ID
      );
      if (
        selectedOrg2Item &&
        selectedOrg2Item.subUnits &&
        selectedOrg2Item.subUnits.length
      ) {
        this.utils.dropdownData.org3Units = selectedOrg2Item.subUnits.map(
          (o) => ({ code: o.code, name: o.name })
        );
        item.org3 = this.utils.getStringValueOfSelect(
          item.org3ID,
          this.utils.dropdownData.org3Units
        );
      }
    }

    if (this.utils.dropdownData.org3Units && item.org3ID && item.org4ID) {
      this.apiService.getOrg3Units(item.org3ID).subscribe(
        (org3Units) => {
          if (
            org3Units.length &&
            org3Units[0].subUnits &&
            org3Units[0].subUnits.length
          ) {
            this.utils.dropdownData.org4Units = org3Units[0].subUnits.map(
              (o) => ({ code: o.code, name: o.name })
            );
            item.org4 = this.utils.getStringValueOfSelect(
              item.org4ID,
              this.utils.dropdownData.org4Units
            );
          }
          if (this.utils.dropdownData.org4Units && item.org4ID) {
            this.setOrg5Value(item);
          }
        },
        (error) => {
          this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
            CONSTANTS.Toaster.ERROR,
            error.message,
          ]);
        }
      );
    }
  }

  setOrg5Value(item) {
    this.apiService.getOrg4Units(item.org4ID).subscribe(
      (org4Units) => {
        if (
          org4Units.length &&
          org4Units[0].subUnits &&
          org4Units[0].subUnits.length
        ) {
          this.utils.dropdownData.org5Units = org4Units[0].subUnits.map(
            (o) => ({ code: o.code, name: o.name })
          );
          item.org5 = this.utils.getStringValueOfSelect(
            item.org5ID,
            this.utils.dropdownData.org5Units
          );
        }
      },
      (error) => {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
          CONSTANTS.Toaster.ERROR,
          error.message,
        ]);
      }
    );
  }

  get utils(): UtilsService {
    return this.utilsService;
  }

  editPlan(): void {
    this.bsPreviewModalRef.hide();
    this.router.navigate([`/learning-plan/edit/${this.planId}`]);
  }

  makeCopy(): void {
    this.bsPreviewModalRef.hide();
    this.router.navigate([`/learning-plan/copy/${this.planId}`]);
  }

  publishPlan(): void {
    const { emptyParts, learningItems } = this.checkForInvalidParts();
    if (emptyParts.length) {
      this.openModalEmptyPart(emptyParts);
      return;
    }

    if (learningItems.filter((item) => item.activated).length < 3) {
      this.openModalAtLeast3items();
      return;
    }

    this.isPublishing = true;
    this.bsPublishModalRef.hide();
    this.previewPlanService
      .publishLearningTemplate(this.planId)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.isPublishing = false;
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            this.setPlanMetaData(res.publishUserTemplate);
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  switchToDraft(): void {
    this.isSwichingToDraft = true;
    this.previewPlanService
      .moveTemplateToDraft(this.planId)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => (this.isSwichingToDraft = false))
      )
      .subscribe(
        (res) => {
          if (res) {
            this.setPlanMetaData(res.moveToDraftTemplate);
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  setPlanMetaData(updatedMetadata): void {
    this.planMetadata.status = updatedMetadata.status;
    this.planMetadata.draftWaiting = updatedMetadata.draftWaiting;
    this.planMetadata.publishedAt = updatedMetadata.publishedAt;
  }

  openDeleteModal(): void {
    if (this.planMetadata.status === LearningTemplateStatus.Published) {
      return;
    }
    this.showDeleteModal = true;
  }

  public deletePreviewPlan(code): void {
    this.showLoader = true;
    this.closeDeletePlanModal();
    this.previewPlanService.deleteLearningTemplate(code).subscribe(
      () => {
        this.showLoader = false;
        this.bsPreviewModalRef.hide();
        this.removeTemplateCallback();
      },
      (error) => {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
          CONSTANTS.Toaster.ERROR,
          error.message,
        ]);
      }
    );
  }

  closeDeletePlanModal(): void {
    this.deletePlanModal.close();
    this.showDeleteModal = false;
  }

  checkForInvalidParts(): {
    emptyParts: Array<string>;
    learningItems: Array<any>;
  } {
    return this.parts.reduce(
      (acc, part) => {
        if (!part.items.length) {
          acc.emptyParts.push(part.name);
        } else {
          acc.learningItems.push(...part.items);
        }
        return acc;
      },
      { emptyParts: [], learningItems: [] }
    );
  }

  openModalEmptyPart(emptyParts: string[]) {
    this.emptyModalRef = this.modalService.show(ActionPopupComponent, {
      ignoreBackdropClick: true,
      id: 2,
    });
    this.emptyModalRef.content.title = this.translateService.getText(
      'templateLearning.emptyPartPopup.title'
    );
    this.emptyModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.emptyPartPopup.closeBtnName'
    );
    this.emptyModalRef.content.cancelBtnText = this.translateService.getText(
      'templateLearning.emptyPartPopup.cancelBtnText'
    );
    this.emptyModalRef.content.header = `“${emptyParts.join(
      '“, “'
    )}” ${this.translateService.getText(
      'templateLearning.emptyPartPopup.header'
    )}`;
    this.emptyModalRef.content.text = this.translateService.getText(
      'templateLearning.emptyPartPopup.text'
    );
    this.emptyModalRef.content.isLargeButton = true;
  }

  openModalAtLeast3items() {
    this.emptyModalRef = this.modalService.show(ActionPopupComponent, {
      ignoreBackdropClick: true,
      id: 2,
    });
    this.emptyModalRef.content.title = this.translateService.getText(
      'templateLearning.lessThen3items.title'
    );
    this.emptyModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.lessThen3items.closeBtnName'
    );
    this.emptyModalRef.content.cancelBtnText = this.translateService.getText(
      'templateLearning.lessThen3items.cancelBtnText'
    );
    this.emptyModalRef.content.header = this.translateService.getText(
      'templateLearning.lessThen3items.header'
    );
    this.emptyModalRef.content.text = this.translateService.getText(
      'templateLearning.lessThen3items.text'
    );
    this.emptyModalRef.content.isLargeButton = true;
  }

  openModalLocked() {
    this.bsModalRefLocked = this.modalService.show(ActionPopupComponent, {
      initialState: {
        onAction: (result) => {
          if (result === 'locked') {
            this.bsModalRefLocked.hide();
          }
        },
      },
    });

    this.bsModalRefLocked.onHide.subscribe(() => {
      this.router.navigate(['/home']);
    });
    this.bsModalRefLocked.content.title = this.translateService.getText(
      'templateLearning.lockedPopup.title'
    );
    this.bsModalRefLocked.content.text = this.translateService.getText(
      'templateLearning.lockedPopup.text'
    );
    this.bsModalRefLocked.content.closeBtnName = this.translateService.getText(
      'templateLearning.lockedPopup.closeBtnName'
    );
    this.bsModalRefLocked.content.actionBtnText = this.translateService.getText(
      'templateLearning.lockedPopup.cancelBtnText'
    );
    this.bsModalRefLocked.content.actionValue = 'locked';
    this.bsModalRefLocked.content.icon = 'icons/locked.svg';
    this.bsModalRefLocked.content.isLargeButton = true;
  }

  openModalDuplication() {
    this.duplicationModalRef = this.modalService.show(ActionPopupComponent, {
      ignoreBackdropClick: true,
      id: 2,
    });
    this.duplicationModalRef.content.title = this.translateService.getText(
      'templateLearning.duplicationPopup.title'
    );
    this.duplicationModalRef.content.text = this.translateService.getText(
      'templateLearning.duplicationPopup.text'
    );
    this.duplicationModalRef.content.closeBtnName =
      this.translateService.getText(
        'templateLearning.duplicationPopup.closeBtnName'
      );
    this.duplicationModalRef.content.cancelBtnText =
      this.translateService.getText(
        'templateLearning.duplicationPopup.cancelBtnText'
      );
    this.duplicationModalRef.content.isLargeButton = true;
  }

  publishModalConfirm(plan) {
    this.bsPublishModalRef = this.modalService.show(ActionPopupComponent, {
      ignoreBackdropClick: true,
      id: 2,
      backdrop: 'static',
      initialState: {
        onAction: (result) => {
          if (result) {
            this.publishPlan();
          }
        },
      },
    });
    this.bsPublishModalRef.content.title = this.translateService.getText(
      'templateLearning.publishPlanPopup.title'
    );
    this.bsPublishModalRef.content.header = `${this.translateService.getText(
      'templateLearning.publishPlanPopup.header'
    )} "${plan.title}"?`;
    this.bsPublishModalRef.content.text = this.translateService.getText(
      'templateLearning.publishPlanPopup.text'
    );
    this.bsPublishModalRef.content.closeBtnName = this.translateService.getText(
      'templateLearning.publishPlanPopup.closeBtnName'
    );
    this.bsPublishModalRef.content.cancelBtnText =
      this.translateService.getText(
        'templateLearning.publishPlanPopup.cancelBtnText'
      );
    this.bsPublishModalRef.content.actionBtnText =
      this.translateService.getText(
        'templateLearning.publishPlanPopup.actionBtnText'
      );
    this.bsPublishModalRef.content.actionValue = true;
    this.bsPublishModalRef.content.isLargeButton = false;
  }

  exportPdf(code, title) {
    this.showLoader = true;
    this.previewPlanService.exportPdf(code).subscribe(
      res => {
        if (res.data) {
          this.showLoader = false;
          this.pdfGeneratorService.generatePdf(res.data, title);
        }
      },
      error => {
        this.showLoader = false;
      }
    );
  }

  showLinkModal(): void {
    this.bsLinkModalRef = this.modalService.show(LinkPlanPopupComponent, { initialState: { link: this.planLink } });
  }

  private handleError(error) {
    if (!this.bsPreviewModalRef) {
      if (error.errorType === 'LOCKED') {
        this.openModalLocked();
      } else if (error.errorType === 'DUPLICATION') {
        this.openModalDuplication();
      } else {
        this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
          CONSTANTS.Toaster.ERROR,
          error.message,
        ]);
      }
    } else if (!this.bsPreviewModalRef) {
      this.eventBus.emit(CONSTANTS.EVENTS.SHOW_TOASTER, [
        CONSTANTS.Toaster.ERROR,
        error.message,
      ]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
