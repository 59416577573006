<div class="mh-popup-header d-flex align-items-center" *ngIf="!showLoader">
  <span class="label-h1">{{ planInfo?.title }}</span>
  <div class="close-popup-button" role="button" (click)="bsPreviewModalRef.hide()">
    <img src="/assets/icons/close-icon.svg" />
  </div>
</div>

<div class="modal-body d-flex flex-column justify-content-center mb-5" *ngIf="!showLoader">
  <div class="plan-info card mb-4 p-sticky" *ngIf="planId">
    <div class="card-body d-flex justify-content-between">
      <div class="d-flex">
        <div class="auto-save details d-flex flex-column align-items-start">
          <span class="font-weight-bold m-0">

            {{ "templateLearning.lastSaved" | amdocsTranslate }}
            <span *ngIf="!planMetadata?.updatedAt"> Not published </span>
          </span>
          <span>{{ planMetadata?.updatedAt | date : "d MMM yyyy | H:mm:ss" }}</span>
        </div>
        <div class="last-publis ml-4 details d-flex flex-column align-items-start">
          <span class="font-weight-bold m-0"> {{ "templateLearning.lastPublished" | amdocsTranslate }}</span>
          <span *ngIf="planMetadata?.publishedAt">{{
            planMetadata?.publishedAt | date : "d MMM yyyy | H:mm"
            }}</span>
          <span *ngIf="!planMetadata?.publishedAt"> Not published </span>
        </div>

        <div class="separation-line ml-4 mr-4"></div>

        <div class="template-right-status d-flex flex-row row-wrap align-items-center">
          <div *ngIf="
              LearningTemplateStatus.Published === planMetadata.status && !isPublishing && !isSwichingToDraft &&
              (planMetadata.draftWaiting === 'false' ||
                planMetadata.draftWaiting === false)
            " class="published d-flex align-items-center">
            <img lib-amdocs-image-version src="/assets/icons/published-plan-status.svg" />
            <span class="ml-2 d-flex flex-column">
              {{ "templateLearning.publishedStatusLabel" | amdocsTranslate }}
            </span>
          </div>

          <div *ngIf="LearningTemplateStatus.Draft === planMetadata.status && !isPublishing && !isSwichingToDraft"
            class="d-flex align-items-center">
            <img lib-amdocs-image-version src="/assets/icons/draft-plan-status.svg" />
            <span class="ml-2 d-flex flex-column">
              {{ "templateLearning.draftStatusLabel" | amdocsTranslate }}
            </span>
          </div>

          <div *ngIf="
              LearningTemplateStatus.Published === planMetadata?.status && !isPublishing && !isSwichingToDraft &&
              (planMetadata?.draftWaiting === 'true' ||
                planMetadata?.draftWaiting === true)
            " class="d-flex align-items-center draft-waiting">
            <img lib-amdocs-image-version src="/assets/icons/publish-d-plan-status.svg" />
            <span class="ml-2 d-flex flex-column">
              {{ "templateLearning.publishedStatusLabel" | amdocsTranslate }}
              <span class="draft-waiting-label">
                {{ "templateLearning.draftWaiting" | amdocsTranslate }}</span>
            </span>
          </div>
        </div>
      </div>

      <div class="plan-info-buttons d-flex">
        <button type="button" class="btn btn-small btn-link ml-2 d-flex align-items-center"
          [ngClass]="{ disabled: showLoader }" (click)="editPlan()">
          <img lib-amdocs-image-version src="/assets/icons/edit.svg"
            [alt]="'templateLearning.editPlan' | amdocsTranslate" />
          <span>
            {{ "templateLearning.editPlan" | amdocsTranslate }}</span>
        </button>
        <button type="button" class="btn btn-small btn-link ml-2 d-flex align-items-center"
          [ngClass]="{ disabled: showLoader }" (click)="makeCopy()">
          <img lib-amdocs-image-version src="/assets/icons/file-copy.svg"
            [alt]="'templateLearning.makeCopy' | amdocsTranslate" />
          <span>
            {{ "templateLearning.makeCopy" | amdocsTranslate }}</span>
        </button>
        <button type="button" class="btn btn-small btn-link ml-2 d-flex align-items-center"
          *ngIf="planMetadata.status !== LearningTemplateStatus.Published" [ngClass]="{ disabled: isPublishing }"
          (click)="publishModalConfirm(planInfo)">
          <img lib-amdocs-image-version src="/assets/icons/publish.svg"
            [alt]="'templateLearning.publishPlan' | amdocsTranslate" />
          <span>
            {{ isPublishing ? ('templateLearning.publishing' | amdocsTranslate) : 'templateLearning.publishPlan' | amdocsTranslate }}
          </span>
        </button>
        <button *ngIf="planMetadata.status && planMetadata.status !== LearningTemplateStatus.Draft" type="button"
          [ngClass]="{ disabled: isSwichingToDraft }" class="btn btn-small btn-link ml-2 d-flex align-items-center"
          (click)="switchToDraft()">
          <img lib-amdocs-image-version src="/assets/icons/draft.svg"
            [alt]="'templateLearning.switchToDraft' | amdocsTranslate" />
          <span>
            {{ "templateLearning.switchToDraft" | amdocsTranslate }}</span>
        </button>
        <button type="button" class="btn btn-small btn-link ml-2 d-flex align-items-center" [ngClass]="{
            disabled: planMetadata.status === LearningTemplateStatus.Published || showLoader
          }" (click)="openDeleteModal()">
          <img lib-amdocs-image-version src="/assets/icons/delete.svg"
            [alt]="'templateLearning.deletePlan' | amdocsTranslate" />
          <span> {{ "templateLearning.deletePlan" | amdocsTranslate }}</span>
        </button>

        <div class="template-right-additional d-flex">
          <lib-amdocs-popover
            #popOver
            [trigger]="'click'"
            [placement]="'bottom right'"
            class="ml-1 d-flex align-items-center"
          >
            <div popoverOpener>
              <img
                lib-amdocs-image-version
                class="dots"
                src="/assets/icons/triple-dots.svg"
              />
            </div>
            <div popoverContent>
              <div class="name-wrapper label-h3">
                <a
                  class="action d-flex align-items-center"
                  (click)="exportPdf(planCode, planInfo?.title)"
                >
                  <img
                    class="mr-3"
                    lib-amdocs-image-version
                    src="/assets/icons/export-pdf.svg"
                    alt="Export to PDF"
                  />
                  <span>Export to PDF</span>
                </a>
                <a
                  class="action d-flex align-items-center"
                  *ngIf="planMetadata.status === LearningTemplateStatus.Published"
                  (click)="showLinkModal()"
                >
                  <img
                    class="mr-3"
                    lib-amdocs-image-version
                    src="/assets/icons/link.svg"
                    alt="Link to published plan"
                  />
                  <span>Link to published plan</span>
                </a>
              </div>
            </div>
          </lib-amdocs-popover>
        </div>

      </div>
    </div>
  </div>

  <div class="card" *ngIf="showPlanInfo">
    <div class="card-body">
      <div class="plan-details-wrapper text-left d-flex">
        <div class="plan-item-zone description d-flex flex-column"
          *ngIf="planInfo?.description || planInfo?.prerequisites">
          <div class="plan-info-line d-flex flex-column mb-2" *ngIf="planInfo?.description">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.descriptionLabel" | amdocsTranslate
              }}</span>
            <span class="plan-value">{{ planInfo.description }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.prerequisites">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.prerequisites" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{
              planInfo.prerequisites
              }}</span>
          </div>
        </div>

        <div class="plan-item-zone" *ngIf="planInfo?.expectedTime || planInfo?.seniority || planInfo?.role">
          <div class="plan-info-line mb-2" *ngIf="planInfo?.expectedTime">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.expectedToFinish" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">
              {{
              "templateLearning.expectedToFinishMonth"
              | amdocsTranslate : [planInfo.expectedTime]
              }}
            </span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.seniority">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.seniority" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo.seniority }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.role">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.role" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo?.role }}</span>
          </div>
        </div>

        <div class="plan-item-zone" *ngIf="planInfo?.product || planInfo?.locationLabel || planInfo?.account">
          <div class="plan-info-line mb-2" *ngIf="planInfo?.product">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.product" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo?.product }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.locationLabel">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.location" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{
              planInfo.locationLabel
              }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.account">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.account" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo.account }}</span>
          </div>
        </div>

        <div class="plan-item-zone" *ngIf="planInfo?.org2 || planInfo?.org3 || planInfo?.org4 || planInfo?.org5">
          <div class="plan-info-line mb-2" *ngIf="planInfo?.org2">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.org2" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo.org2 }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.org3">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.org3" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo?.org3 }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.org4">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.org4" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo?.org4 }}</span>
          </div>
          <div class="plan-info-line mb-2" *ngIf="planInfo?.org5">
            <span class="plan-label font-weight-bold">{{
              "templateLearning.org5" | amdocsTranslate
              }}</span>
            <span class="ml-1 plan-value">{{ planInfo.org5 }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-learning-plan-wrapper mt-4 text-left" *ngIf="planId">
    <app-learning-plan #LearningPlan [parts]="parts" [learningTypesList]="learningTypesList"
      [learningPlanType]="LearningPlanType.Create" [isPreviewMode]="true" (openLibraryCallback)="openLibrary($event)"
      (editCallback)="schedulePlanSave()" (removeItemCallback)="removeItem($event)"
      (updateLearninPlanCallback)="schedulePlanSave()"></app-learning-plan>
  </div>
</div>

<lib-amdocs-popup *ngIf="showDeleteModal" [showCloseButton]="true" #deletePlanModal [customClass]="'delete-plan-modal'"
  (closeCallback)="showDeleteModal = false">
  <div class="mh-popup-header">
    <span class="label-h1" *ngIf="!isDeleted">{{
      "templateLearning.deletePlan" | amdocsTranslate
      }}</span>

    <span class="label-h1" *ngIf="isDeleted">{{
      "templateLearning.deletePlan" | amdocsTranslate
      }}</span>
  </div>

  <div class="modal-body d-flex flex-column justify-content-center">
    <div class="title" *ngIf="!isDeleted">
      {{
      "templateLearning.deletePlanTitle"
      | amdocsTranslate : [planDetails.title]
      }}
    </div>
    <div class="title" *ngIf="isDeleted">
      {{
      "templateLearning.deletePlanTitleSuccess"
      | amdocsTranslate : [planDetails.title]
      }}
    </div>

    <div class="mt-5 sub-title" *ngIf="!isDeleted">
      {{ "templateLearning.deletePlanModalSubtitle" | amdocsTranslate }}
    </div>

    <div class="mt-5" *ngIf="!isDeleted">
      <button type="button" class="btn btn-medium btn-outline mr-4" (click)="closeDeletePlanModal()">
        {{ "templateLearning.modalCancel" | amdocsTranslate }}
      </button>
      <button type="button" class="btn btn-medium dark" (click)="deletePreviewPlan(planId)">
        {{ "templateLearning.modalConfirm" | amdocsTranslate }}
      </button>
    </div>

    <div class="mt-5" *ngIf="isDeleted">
      <button type="button" class="btn btn-medium btn-outline" (click)="closeDeletePlanModal()">
        {{ "templateLearning.backToHome" | amdocsTranslate }}
      </button>
    </div>
  </div>
</lib-amdocs-popup>

<lib-amdocs-app-loader *ngIf="showLoader"></lib-amdocs-app-loader>